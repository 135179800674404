import React from "react";
import { HomeItem } from "./Home/HomeItem";
import { H2 } from "./shared/primitives/Heading";
import Paragraph from "./shared/primitives/Paragraph";
import { ButtonAsHref } from "./shared/primitives/Button";
import { externalLinks } from "config";
export default function Events() {
    return (React.createElement(HomeItem, null,
        React.createElement(H2, { color: "primary" }, "Veranstaltungen"),
        React.createElement(Paragraph, null, "Hier findest Du eine \u00DCbersicht der bevorstehenden Veranstaltungen."),
        React.createElement(ButtonAsHref, { href: externalLinks.events, noFloat: true }, "Termine anzeigen")));
}
