var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import styled from "styled-components";
import { externalLinks, routes, style as styleConfig } from "config";
import { H1, H2 } from "components/shared/primitives/Heading";
import Paragraph from "components/shared/primitives/Paragraph";
import { HomeItem } from "../HomeItem";
import { ButtonAsHref, ButtonAsLink } from "components/shared/primitives/Button";
function TrainingVideo() {
    return (React.createElement(HomeItem, { desktop: { padding: "3em" }, gray: true },
        React.createElement("video", { controls: true, style: { width: "100%", height: "auto", maxWidth: "1080px" } },
            React.createElement("source", { src: "/videos/230313_Triaphon-Master-v01.mp4", type: "video/mp4" }),
            "Schulungsvideo ",
            React.createElement("a", { href: "/videos/230313_Triaphon-Master-v01.mp4" }, "herunterladen"))));
}
function CustomerHomeItem(props) {
    const { heading, buttonText, link, children, inverse = false, linkIsExternal = false } = props, rest = __rest(props, ["heading", "buttonText", "link", "children", "inverse", "linkIsExternal"]);
    const sharedButtonProps = { children: buttonText, auto: true, inverse };
    return (React.createElement(HomeItem, Object.assign({ isInverse: inverse, split: true }, rest),
        React.createElement(H2, { color: inverse ? "white" : "primary" }, heading),
        React.createElement(Paragraph, { color: inverse ? "white" : undefined }, children),
        linkIsExternal ? (React.createElement(ButtonAsHref, Object.assign({ href: link }, sharedButtonProps))) : (React.createElement(ButtonAsLink, Object.assign({ to: link }, sharedButtonProps)))));
}
function TrainingMaterial() {
    return (React.createElement(CustomerHomeItem, { heading: "Schulungsmaterial", buttonText: "Schulungsmaterial ansehen", link: externalLinks.teachingMaterial, desktop: { column: 1 / 2 }, linkIsExternal: true }, "Ausf\u00FChrliche Informationen zum Umgang mit Triaphon finden Sie in unserem Bereich mit Schulungsmaterial."));
}
function StatsInfo() {
    return (React.createElement(CustomerHomeItem, { heading: "Statistik", buttonText: "Statistik ansehen", link: routes.customerStats(), desktop: { column: 1 / 2 } }, "Erhalten Sie jetzt einen Einblick in ihre aktuellen Anrufdaten."));
}
function PhoneNumbersInfo() {
    return (React.createElement(CustomerHomeItem, { heading: "Telefonnummern", buttonText: "Telefonnummern anpassen", link: routes.managePhoneNumbers(), desktop: { column: 1 / 2 }, inverse: true }, "F\u00FCgen Sie weitere Nummern hinzu, oder ersetzen Sie bestehende Nummern."));
}
function AddressInfo() {
    return (React.createElement(CustomerHomeItem, { heading: "Adresse", buttonText: "Adresse bearbeiten", link: routes.manageAddress(), desktop: { column: 1 / 2 }, inverse: true }, "Bei einem Umzug k\u00F6nnen Sie hier ihre Adresse bearbeiten."));
}
function PaymentModelInfo() {
    return (React.createElement(CustomerHomeItem, { heading: "Konditionen", buttonText: "Konditionen ansehen", link: routes.paymentModel(), desktop: { column: 1 / 2 } }, "Erhalten Sie weitere Informationen \u00FCber Ihre Konditionen."));
}
function CustomerInvoices() {
    return (React.createElement(CustomerHomeItem, { heading: "Rechnungen", buttonText: "Rechnungen ansehen", link: routes.customerInvoices(), desktop: { column: 1 / 2 } }, "Erhalten Sie detaillierte Informationen zu Ihren Rechnungen und Einzelverbindungsnachweisen."));
}
const InfoRow = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 4em;
  background-color: ${(p) => (p.inverse ? styleConfig.colors.primary : styleConfig.colors.secondary)};

  @media (${styleConfig.media.desktop}) {
    flex-direction: row;
  }
`;
export default function CustomerHome() {
    return (React.createElement("div", null,
        React.createElement(HomeItem, { desktop: { padding: "3em" }, isInverse: true },
            React.createElement(H1, { color: "secondary", solo: true }, "Willkommen bei Triaphon")),
        React.createElement(TrainingVideo, null),
        React.createElement(InfoRow, null,
            React.createElement(TrainingMaterial, null),
            React.createElement(StatsInfo, null)),
        React.createElement(InfoRow, { inverse: true },
            React.createElement(PhoneNumbersInfo, null),
            React.createElement(AddressInfo, null)),
        React.createElement(InfoRow, null,
            React.createElement(PaymentModelInfo, null),
            React.createElement(CustomerInvoices, null))));
}
