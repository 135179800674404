import React from "react";
import styled from "styled-components";
import { style as styleConfig } from "config";
const { colors } = styleConfig;
const Root = styled.div `
  margin: 0 auto;
  width: 100px;
  text-align: center;
`;
const Bounce = styled.div `
  width: 15px;
  height: 15px;
  margin: 0 5px;
  background-color: ${(p) => (p.color ? colors[p.color] : undefined) || "#fff"};
  border-radius: 100%;
  display: inline-block;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;

  @keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
      transform: scale(0.5);
    }

    40% {
      transform: scale(1);
    }
  }
`;
const Bounce1 = styled(Bounce) `
  animation-delay: -0.32s;
`;
const Bounce2 = styled(Bounce) `
  animation-delay: -0.16s;
`;
export default function Spinner(props) {
    const { color } = props;
    return (React.createElement(Root, null,
        React.createElement(Bounce1, { color: color }),
        React.createElement(Bounce2, { color: color }),
        React.createElement(Bounce, { color: color })));
}
