import * as React from "react";
import styled from "styled-components";
import { formatDayShort, formatTime, getTime } from "components/helpers/dateFormatting";
import Icon from "components/shared/primitives/Icon";
import Button, { ButtonAsLink } from "components/shared/primitives/Button";
import { Paragraph } from "components/shared/primitives/Paragraph";
import { H2 } from "components/shared/primitives/Heading";
import Countdown from "./Countdown";
import { routes } from "config";
import { HomeItem } from "components/Home/HomeItem";
const NoWrap = styled.span `
  white-space: nowrap;
`;
function StatusWrapper(props) {
    const { children, isInverse } = props;
    return (React.createElement(HomeItem, { isInverse: isInverse },
        React.createElement(H2, { color: `${isInverse ? "secondary" : "primary"}` }, "Status"),
        children));
}
function CurrentShiftStatus(props) {
    const handleClick = () => {
        const { currentShift, destroyShift } = props;
        destroyShift(currentShift.id, { onSuccess: () => window.scrollTo(0, 0) });
    };
    const { currentShift } = props;
    return (React.createElement(StatusWrapper, { isInverse: true },
        React.createElement(Paragraph, null,
            React.createElement(Icon, { icon: "comment", large: true, color: "white", margin: "0" })),
        React.createElement(Paragraph, { color: "white" },
            currentShift.scheduled ? "Du bist auf Schicht!" : "Du bist flexibel auf Schicht!",
            " ",
            currentShift.scheduled && [
                "Sie läuft bis ",
                React.createElement(NoWrap, { key: currentShift.id },
                    getTime(currentShift.endsAt),
                    " Uhr"),
            ],
            !currentShift.scheduled && [
                "Sie läuft bis maximal ",
                React.createElement(NoWrap, { key: currentShift.id },
                    getTime(currentShift.endsAt),
                    " Uhr"),
            ],
            ".",
            " ",
            React.createElement(NoWrap, null,
                "(noch ",
                React.createElement(Countdown, { endsAt: currentShift.endsAt }),
                ")")),
        !currentShift.scheduled && (React.createElement(Button, { inverse: true, onClick: handleClick }, "Flexible Schicht beenden"))));
}
function ScheduledShiftStatus(props) {
    const { nextScheduledShift } = props;
    return (React.createElement(StatusWrapper, { isInverse: false },
        React.createElement(Paragraph, null,
            React.createElement(Icon, { medium: true, icon: "calendar", margin: "0" })),
        React.createElement(Paragraph, null,
            "Deine n\u00E4chste Schicht startet am",
            " ",
            React.createElement(NoWrap, null,
                formatDayShort(nextScheduledShift.startsAt),
                " um ",
                formatTime(nextScheduledShift.startsAt)))));
}
function NoShiftsStatus() {
    return (React.createElement(StatusWrapper, { isInverse: false },
        React.createElement(Paragraph, { color: "inherit" }, "Hier siehst du den Status deiner Schichten"),
        React.createElement(Paragraph, { color: "gray" }, "Du hast noch keine Schicht ausgew\u00E4hlt"),
        React.createElement(ButtonAsLink, { inverse: false, to: routes.planShifts() }, "Jetzt Schicht w\u00E4hlen")));
}
export default function Status(props) {
    const { currentShift, nextScheduledShift, destroyShift } = props;
    if (currentShift) {
        return React.createElement(CurrentShiftStatus, { currentShift: currentShift, destroyShift: destroyShift });
    }
    else if (nextScheduledShift) {
        return React.createElement(ScheduledShiftStatus, { nextScheduledShift: nextScheduledShift });
    }
    else {
        return React.createElement(NoShiftsStatus, null);
    }
}
