import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "reduxStore";
import { useParams } from "react-router-dom";
import { selectTranslatorCreditNote } from "selectors/translator";
import { getTranslatorCreditNoteAction } from "slices/translator";
import FlexContainer from "./shared/layout/FlexContainer";
import { H2 } from "./shared/primitives/Heading";
import Spinner from "./shared/Spinner";
import Paragraph from "./shared/primitives/Paragraph";
import Link from "./shared/primitives/Link";
import styled from "styled-components";
import { GridContainer, GridItem } from "./shared/layout/Grid";
import FlexItem from "./shared/layout/FlexItem";
import { unitsTranslations } from "./helpers/translations";
const PositionsGridContainer = styled(GridContainer) `
  grid-template-columns: 1fr 3fr 2fr 2fr;
`;
const HeadingGridItem = styled(GridItem) `
  margin-bottom: 0.5em;
`;
export default function TranslatorCreditNote() {
    const dispatch = useAppDispatch();
    const creditNoteState = useSelector(selectTranslatorCreditNote);
    const { creditNoteId } = useParams();
    const { data: creditNote, loading } = creditNoteState;
    useEffect(() => {
        if (!creditNoteId)
            return;
        const params = { id: creditNoteId };
        dispatch(getTranslatorCreditNoteAction(params));
    }, [creditNoteId]);
    if (loading || !creditNote) {
        return (React.createElement(FlexContainer, { flexDirection: "column", fullHeight: true },
            React.createElement(FlexItem, { padding: "2em 0", desktop: { padding: "2em 0" } },
                loading && React.createElement(Spinner, { color: "primary" }),
                !loading && !creditNote && React.createElement(Paragraph, null, "Gutschrift konnte nicht geladen werden."))));
    }
    const { title, pdf, positions } = creditNote.sevdeskCreditNote;
    return (React.createElement(FlexContainer, { flexDirection: "column" },
        React.createElement(FlexItem, { padding: "2em 0", desktop: { padding: "2em 0" }, column: 1 / 1 },
            React.createElement(FlexContainer, { gap: "2em", flexDirection: "column" },
                React.createElement(H2, null, title),
                React.createElement(PositionsGridContainer, null,
                    React.createElement(HeadingGridItem, null,
                        React.createElement("b", null, "Menge")),
                    React.createElement(HeadingGridItem, null,
                        React.createElement("b", null, "Name")),
                    React.createElement(HeadingGridItem, null,
                        React.createElement("b", null, "Preis pro Einheit (\u20AC)")),
                    React.createElement(HeadingGridItem, null,
                        React.createElement("b", null, "Preis (\u20AC)")),
                    positions.map((p) => (React.createElement(React.Fragment, null,
                        React.createElement(GridItem, null, `${p.quantity} (${unitsTranslations[p.unit] || "pauschal"})`),
                        React.createElement(GridItem, null, p.name),
                        React.createElement(GridItem, null, p.pricePerUnit.toFixed(2)),
                        React.createElement(GridItem, null, p.price.toFixed(2)))))),
                pdf && (React.createElement(Link, { download: pdf.name, href: `data:application/pdf;base64,${pdf.content}` }, "Download PDF"))))));
}
