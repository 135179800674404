import styled, { css } from "styled-components";
import { style as styleConfig } from "config";
import { Base } from "./Heading";
const { colors } = styleConfig;
export const Paragraph = styled.p `
  color: ${(p) => (p.color ? colors[p.color] : undefined) || "inherit"};
  margin: 0 0 1em;
  line-height: 1.4em;
  ${({ centered }) => centered &&
    css `
      text-align: center;
    `};
  ${({ paddingTop }) => paddingTop &&
    css `
      padding-top: ${paddingTop};
    `};
  &:last-child {
    margin-bottom: 0;
  }
`;
export const Address = styled(Paragraph).attrs({ as: "address" }) `
  font-style: normal;
  font-family: inherit;
`;
export const Name = styled(Base).attrs({ as: "span" }) `
  color: ${styleConfig.colors.black};
  font-weight: bold;
  font-size: 1.2em;
  margin: 0.25em 0;
`;
export default Paragraph;
