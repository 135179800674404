import * as React from "react";
import { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import validator from "validator";
import { style as styleConfig, routes } from "../config";
const { colors } = styleConfig;
import FlexContainer from "./shared/layout/FlexContainer";
import FlexItem from "./shared/layout/FlexItem";
import { RouterLink as Link } from "./shared/primitives/Link";
import Paragraph from "./shared/primitives/Paragraph";
import InputField from "./shared/primitives/InputField";
import Button from "./shared/primitives/Button";
import Spinner from "./shared/Spinner";
import Checkbox from "./shared/primitives/Checkbox";
import Title from "./shared/layout/Title";
import { useAppDispatch } from "reduxStore";
import { createUserSessionAction } from "slices/userSession";
import { selectUserSession } from "selectors/userSession";
import { selectLoggedIn } from "selectors/user";
import { useDisplayCustomerPrompts } from "customHooks";
const Root = styled(FlexContainer) `
  flex-direction: column;
`;
const MainContainer = styled(FlexItem) `
  background-color: ${colors.lightGray};
`;
const InputWrapper = styled.div `
  width: 100%;
  margin-bottom: 1em;

  ${(props) => props.centered && "text-align: center;"};
`;
const LoginButton = styled(Button) `
  margin-top: 1em;
`;
const LoginForm = styled.form `
  display: flex;
  flex-direction: column;
  align-items: center;
  ${(p) => p.hasError &&
    css `
      input {
        border-color: ${colors.primary};
      }
    `};
`;
const InfoText = styled(Paragraph) `
  color: ${colors.green};
`;
const Label = styled.label `
  display: flex;
  align-items: center;

  span {
    margin-right: 0.5em;
  }
`;
const CheckboxText = styled.span `
  flex-shrink: 5;
`;
const ForgotPasswordLink = styled(Link) `
  font-size: 0.9em;
  font-weight: bold;
  text-decoration: underline;
  color: ${colors.primary};
`;
const ForgotPassword = styled(FlexItem) ``;
const ForgotPasswordMobile = styled(ForgotPassword) `
  margin-top: 3em;
  padding-top: 1em;
  border-top: 1px solid ${colors.gray};
  text-align: center;
  padding-bottom: 0;
`;
export default function LogIn() {
    const dispatch = useAppDispatch();
    const loggedIn = useSelector(selectLoggedIn);
    const userSessionState = useSelector(selectUserSession);
    const serverError = userSessionState.error;
    const [values, setValues] = useState({ email: "", password: "", remember_me: false });
    const [errors, setErrors] = useState({ email: "", password: "", server: serverError });
    const [touched, setTouched] = useState(false);
    const { isCustomer, searchParamsStr } = useDisplayCustomerPrompts();
    useEffect(() => {
        if (serverError != errors.server) {
            const password = !!serverError ? "Anmeldung ist fehlgeschlagen" : "";
            setErrors(Object.assign(Object.assign({}, errors), { password, server: serverError }));
        }
    }, [JSON.stringify(serverError)]);
    const handleSubmit = (event) => {
        event.preventDefault();
        setTouched(false);
        setErrors(Object.assign(Object.assign({}, errors), { server: null }));
        dispatch(createUserSessionAction(values));
    };
    const handleChange = (event) => {
        const { target } = event;
        const { name, checked, type } = target;
        const { value } = target;
        let emailError = "";
        let finalValue = value;
        if (type === "checkbox") {
            finalValue = checked;
        }
        if (name === "email" && !validator.isEmail(value)) {
            emailError = "Muss eine gültige E-Mail sein";
        }
        setValues(Object.assign(Object.assign({}, values), { [name]: finalValue }));
        setErrors(Object.assign(Object.assign({}, errors), { email: emailError }));
        setTouched(true);
    };
    const showLoginFailed = !touched && !!errors.password;
    const buttonDisabled = !values.email || !values.password || !!errors.email || !!showLoginFailed;
    let loginPrompt = "Melde Dich mit Deiner E-Mail-Adresse und Deinem Passwort an";
    let emailInputLabel = "Deine E-Mail";
    let passwordInputLabel = "Dein Passwort";
    if (isCustomer) {
        loginPrompt = "Melden Sie sich mit Ihrer E-Mail-Adresse und Ihrem Passwort an";
        emailInputLabel = "Ihre E-Mail";
        passwordInputLabel = "Ihr Passwort";
    }
    if (loggedIn) {
        return React.createElement(Navigate, { to: "/", state: { from: "/signin" } });
    }
    else {
        return (React.createElement(Root, null,
            React.createElement(Title, null, "Anmelden"),
            React.createElement(MainContainer, { column: 1 / 1 },
                userSessionState.info && React.createElement(InfoText, null, userSessionState.info),
                React.createElement(Paragraph, null, loginPrompt),
                React.createElement(LoginForm, { onSubmit: handleSubmit, hasError: showLoginFailed },
                    React.createElement(InputWrapper, null,
                        React.createElement(InputField, { label: emailInputLabel, name: "email", error: errors.email, value: values.email, onChange: handleChange })),
                    React.createElement(InputWrapper, null,
                        React.createElement(InputField, { type: "password", label: passwordInputLabel, name: "password", error: errors.password, value: values.password, onChange: handleChange })),
                    React.createElement(InputWrapper, { centered: true },
                        React.createElement(Label, { htmlFor: "remember_me" },
                            React.createElement(Checkbox, { onChange: handleChange, checked: values.remember_me, name: "remember_me", value: values.remember_me ? "1" : "0", id: "remember_me" }),
                            React.createElement(CheckboxText, null, "Angemeldet bleiben"))),
                    React.createElement(ForgotPassword, { column: 1 / 1, display: "none", desktop: { display: "block", textAlign: "right", padding: "0" } },
                        React.createElement(InputWrapper, null,
                            React.createElement(ForgotPasswordLink, { to: routes.forgotPassword(searchParamsStr) }, "Passwort vergessen?"))),
                    React.createElement(LoginButton, { type: "submit", disabled: buttonDisabled }, userSessionState.isLoading ? React.createElement(Spinner, null) : "Anmelden")),
                React.createElement(ForgotPasswordMobile, { column: 1 / 1, desktop: { display: "none" } },
                    React.createElement(ForgotPasswordLink, { to: routes.forgotPassword(searchParamsStr) }, "Passwort vergessen?")))));
    }
}
