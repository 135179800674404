var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import styled from "styled-components";
import { Link as ReactRouterLink } from "react-router-dom";
import { style as styleConfig } from "../../../config";
const { colors } = styleConfig;
const StyledLink = styled.a `
  color: ${(p) => (p.color ? colors[p.color] || p.color : colors.primary)};
  font-weight: ${({ bold = true }) => (bold ? "bold" : "normal")};
  text-decoration: none;
`;
export const RouterLink = styled(StyledLink).attrs({ as: ReactRouterLink }) ``;
export default function Link(props) {
    const { href = "#", bold = true } = props, rest = __rest(props, ["href", "bold"]);
    return React.createElement(StyledLink, Object.assign({}, rest, { href: href, bold: bold }));
}
