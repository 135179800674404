import React from "react";
import styled from "styled-components";
import { style as styleConfig } from "config";
const colors = styleConfig.colors;
const Root = styled.div `
  box-sizing: border-box;

  text-align: ${({ textAlign }) => textAlign || "center"};
  padding: ${({ padding }) => padding || "3em 1em"};
  display: ${({ display }) => display || "block"};
  background-color: ${({ backgroundColor }) => typeof backgroundColor !== "undefined" ? colors[backgroundColor] || backgroundColor : "transparent"};
  align-self: ${({ alignSelf }) => alignSelf || "auto"};
  align-items: ${({ alignItems }) => alignItems || "normal"};
  order: ${({ order }) => order || 0};
  flex-grow: ${({ flexGrow }) => flexGrow || 0};
  flex-shrink: ${({ flexShrink }) => flexShrink || 1};
  flex-direction: ${({ flexDirection }) => flexDirection || "row"};
  ${({ column }) => column && `width: ${column * 100}%;`};

  @media (${styleConfig.media.desktop}) {
    padding: ${({ desktop }) => (desktop && desktop.padding) || "3em 22.5%"};
    display: ${({ desktop, display }) => (desktop && desktop.display) || display || "block"};
    order: ${({ desktop }) => (desktop && desktop.order) || 0};
    text-align: ${({ desktop }) => (desktop && desktop.textAlign) || "left"};
    flex-grow: ${({ desktop, flexGrow }) => (desktop && desktop.flexGrow) || flexGrow || 0};
    flex-shrink: ${({ desktop, flexShrink }) => (desktop && desktop.flexShrink) || flexShrink || 1};
    ${({ desktop }) => desktop && desktop.column && desktop.width && `width: ${desktop.width || "auto"};`}
  }

  ${({ flex }) => flex &&
    `
    flex: ${flex};
    @media (${styleConfig.media.desktop}) {
      flex: ${flex};
    }
  `}
`;
export default function FlexItem(props) {
    const { desktop } = props;
    if (desktop) {
        desktop.width = desktop.column;
        let columnFloat;
        if (typeof desktop.column === "number") {
            columnFloat = desktop.column;
        }
        else if (typeof desktop.column === "string") {
            columnFloat = parseFloat(desktop.column);
        }
        if (columnFloat && !isNaN(columnFloat)) {
            desktop.width = `${columnFloat * 100}%`;
        }
    }
    return React.createElement(Root, Object.assign({}, props, { desktop: desktop || {} }));
}
