import * as React from "react";
import styled, { css } from "styled-components";
import { style as styleConfig } from "../../../config";
import IconBlueprint from "../../shared/primitives/Icon";
import { H3 } from "../../shared/primitives/Heading";
import { Paragraph } from "../../shared/primitives/Paragraph";
const Icon = styled(IconBlueprint) `
  margin: 0 0 1em 0;

  ${(props) => props.centerIcon &&
    css `
      align-self: center;
    `}

  @media (${styleConfig.media.desktop}) {
    margin: 0 1em 0 0;
  }

  ${(props) => props.singleLine &&
    css `
      margin: 0 1em 0 0;
      width: 15vw;
      height: 15vw;
    `}
`;
const Info = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3em 0;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  ${(props) => props.singleLine &&
    css `
      text-align: left;
      flex-direction: row;
      margin: 0 0 1em;
    `}

  @media (${styleConfig.media.desktop}) {
    flex-direction: row;
    align-items: flex-start;

    ${(props) => props.auto &&
    css `
        width: auto;
      `};
  }
`;
function InfoBox(props) {
    const { icon, heading, auto, singleLine, centerIcon, children } = props;
    return (React.createElement(Info, { auto: auto, singleLine: singleLine },
        React.createElement(Icon, { large: true, icon: icon, singleLine: singleLine, centerIcon: centerIcon }),
        React.createElement("div", null,
            React.createElement(H3, null, heading),
            React.createElement(Paragraph, null, children))));
}
export default InfoBox;
