import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { selectCustomer } from "selectors/customer";
import { style as styleConfig } from "config";
import styled from "styled-components";
import FlexContainer from "./shared/layout/FlexContainer";
import Button from "./shared/primitives/Button";
import { HookFormInput } from "./shared/primitives/InputField";
import { useAppDispatch } from "reduxStore";
import { updateCustomerClientAction } from "slices/customer";
import { yupResolver } from "@hookform/resolvers/yup";
import { phoneNumberSchema } from "formSchemas";
import { useForm } from "react-hook-form";
import { H2 } from "./shared/primitives/Heading";
const AddNumberFormRoot = styled(FlexContainer) `
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  gap: 0.5rem;
  margin-bottom: 1rem;
`;
function AddNumberForm(props) {
    var _a;
    const { hideForm, updateClient } = props;
    const { handleSubmit, control, formState: { errors }, } = useForm({
        resolver: yupResolver(phoneNumberSchema),
        defaultValues: { number: "" },
    });
    const addPhoneNumber = (data) => {
        updateClient((client) => ({ id: client.id, phoneNumbersAttributes: [data] }));
    };
    return (React.createElement("form", { onSubmit: handleSubmit(addPhoneNumber) },
        React.createElement(AddNumberFormRoot, { fullWidth: true },
            React.createElement(HookFormInput, { control: control, width: "auto", name: "number", label: "Neue Nummer", error: (_a = errors.number) === null || _a === void 0 ? void 0 : _a.message }),
            !errors.number && React.createElement("small", null, "Bitte L\u00E4ndervorwahl nutzen (z.B. f\u00FCr Berlin +4930\u2026)"),
            React.createElement("div", null,
                React.createElement(Button, { type: "submit", auto: true, slim: true, disabled: Object.keys(errors).length > 0 }, "Hinzuf\u00FCgen"),
                React.createElement(Button, { auto: true, slim: true, inverse: true, onClick: hideForm }, "Abbrechen")))));
}
const PhoneNumberListContainer = styled(FlexContainer) `
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.5rem;
  width: 100%;
  margin-top: 2rem;
`;
function PhoneNumberList(props) {
    const { phoneNumbers, updateClient, addNumberFormIsVisible, hideAddNumberForm } = props;
    const deletePhoneNumber = (phoneNumber) => () => {
        if (window.confirm(`${phoneNumber.number} wirklich löschen?`)) {
            updateClient((client) => ({
                id: client.id,
                phoneNumbersAttributes: [Object.assign(Object.assign({}, phoneNumber), { _destroy: true })],
            }));
        }
    };
    return (React.createElement(PhoneNumberListContainer, null,
        addNumberFormIsVisible && (React.createElement(AddNumberForm, { key: "add-number", hideForm: hideAddNumberForm, updateClient: updateClient })),
        phoneNumbers.length > 0 ? (React.createElement(Fragment, null,
            React.createElement("b", { key: "heading" }, "Nummer"),
            phoneNumbers.map((p) => (React.createElement(FlexContainer, { key: p.id },
                React.createElement("span", null, p.number),
                React.createElement(Button, { auto: true, slim: true, inverse: true, onClick: deletePhoneNumber(p) }, "X")))))) : (React.createElement("i", null, "Keine Nummern vorhanden"))));
}
function ClientSelect(props) {
    const { clients, selectClient, selectedClient } = props;
    return (React.createElement("select", { onChange: selectClient, value: selectedClient.id }, clients.map((client) => (React.createElement("option", { key: client.id, value: client.id }, client.name)))));
}
const ManagePhoneNumbersViewRoot = styled(FlexContainer) `
  margin: auto;
  margin-top: 2rem;
  flex-direction: column;
  width: 95%;
  min-height: 50vh;
  justify-content: flex-start;

  @media (${styleConfig.media.desktop}) {
    max-width: 1024px;
  }
`;
function ManagePhoneNumbersView(props) {
    const { clients, selectedClient, selectClient, updateClient, showAddNumberForm, hideAddNumberForm, addNumberFormIsVisible, } = props;
    return (React.createElement(ManagePhoneNumbersViewRoot, null,
        React.createElement(H2, null, "Telefonnummern"),
        React.createElement(FlexContainer, { gap: "0.5rem", justifyContent: "space-between", fullWidth: true },
            React.createElement(ClientSelect, { clients: clients, selectedClient: selectedClient, selectClient: selectClient }),
            React.createElement(Button, { onClick: showAddNumberForm, disabled: addNumberFormIsVisible, slim: true, auto: true }, "Nummer hinzuf\u00FCgen")),
        React.createElement(PhoneNumberList, { phoneNumbers: selectedClient.phoneNumbers, updateClient: updateClient, addNumberFormIsVisible: addNumberFormIsVisible, hideAddNumberForm: hideAddNumberForm })));
}
export default function ManagePhoneNumbers() {
    const customer = useSelector(selectCustomer);
    const dispatch = useAppDispatch();
    const [selectedClientId, setSelectedClientId] = useState(null);
    const [addNumberFormIsVisible, setAddNumberFormIsVisible] = useState(false);
    if (!customer || customer.clients.length === 0)
        return null;
    const selectedClient = customer.clients.find((c) => c.id === selectedClientId) || customer.clients[0];
    const selectClient = (event) => {
        setSelectedClientId(event.target.value);
    };
    const showAddNumberForm = () => setAddNumberFormIsVisible(true);
    const hideAddNumberForm = () => setAddNumberFormIsVisible(false);
    const updateClient = (getUpdateParams) => {
        const params = getUpdateParams(selectedClient);
        hideAddNumberForm();
        dispatch(updateCustomerClientAction(params));
    };
    return (React.createElement(ManagePhoneNumbersView, { clients: customer.clients, selectedClient: selectedClient, selectClient: selectClient, phoneNumbers: selectedClient.phoneNumbers, showAddNumberForm: showAddNumberForm, hideAddNumberForm: hideAddNumberForm, updateClient: updateClient, addNumberFormIsVisible: addNumberFormIsVisible }));
}
