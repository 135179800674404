// load error reporting first!
import * as Sentry from "@sentry/react";
Sentry.init({
    dsn: process.env.SENTRY_DSN_JS,
    environment: process.env.SENTRY_ENV || process.env.NODE_ENV,
    integrations: [Sentry.browserTracingIntegration()],
    tracesSampleRate: 0.2,
});
import "./config/ahoy";
import "./config/axios";
import "./config/moment";
import React from "react";
import App from "./components/App";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import LocationChange from "./components/LocationChange";
import store from "reduxStore";
const container = document.getElementById("app");
if (container) {
    const root = createRoot(container);
    root.render(React.createElement(BrowserRouter, null,
        React.createElement(Provider, { store: store },
            React.createElement(LocationChange, null,
                React.createElement(App, null)))));
}
