var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { initialLoadAction } from "actions/application";
import * as React from "react";
import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useAppDispatch } from "reduxStore";
import { selectLoggedIn } from "../../selectors/user";
import { redirectToOAuthClient, isOAuth2Request } from "../helpers/oAuthRedirect";
export default function Loading(props) {
    const [initialLoadFinished, setInitialLoadFinished] = useState(false);
    const location = useLocation();
    const dispatch = useAppDispatch();
    const loggedIn = useSelector(selectLoggedIn);
    useEffect(() => {
        const initialLoad = () => __awaiter(this, void 0, void 0, function* () {
            try {
                yield dispatch(initialLoadAction());
            }
            finally {
                setInitialLoadFinished(true);
            }
        });
        initialLoad();
    }, []);
    useEffect(() => {
        const urlParams = location.search;
        if (loggedIn && isOAuth2Request(urlParams)) {
            redirectToOAuthClient(urlParams);
        }
    }, [loggedIn]);
    if (initialLoadFinished) {
        return React.createElement(Fragment, null, props.children);
    }
    else {
        return React.createElement("div", null);
    }
}
