var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as React from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import moment from "moment";
import { getOwnShiftsAction, createShiftAction, destroyShiftAction } from "slices/shifts";
import { selectAreShiftsLoading, selectCurrentShift, selectScheduledShifts } from "selectors/shift";
import FlexContainer from "components/shared/layout/FlexContainer";
import FlexibleShift from "./presentational/FlexibleShift";
import ScheduledShiftsPreview from "./presentational/ScheduledShiftsPreview";
import Status from "./presentational/Status";
import { useAppDispatch } from "reduxStore";
import { HomeItem } from "../HomeItem";
const HomeRoot = styled(FlexContainer) `
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
`;
export default function TranslatorHome() {
    const dispatch = useAppDispatch();
    const [updateCounter, setUpdateCounter] = useState(0);
    const currentShift = useSelector(selectCurrentShift);
    const scheduledShifts = useSelector(selectScheduledShifts);
    const areShiftsLoading = useSelector(selectAreShiftsLoading);
    const getOwnShifts = () => __awaiter(this, void 0, void 0, function* () {
        yield dispatch(getOwnShiftsAction());
        setUpdateCounter(updateCounter + 1);
    });
    const destroyShift = (id) => dispatch(destroyShiftAction(id));
    const createShift = () => dispatch(createShiftAction());
    useEffect(() => {
        if (updateCounter === 0) {
            getOwnShifts();
            return;
        }
        const scheduledTimes = scheduledShifts.map((shift) => shift.startsAt);
        if (currentShift) {
            scheduledTimes.push(currentShift.endsAt);
        }
        let updateIn = null;
        if (scheduledTimes.length > 0) {
            const momentScheduledTimes = scheduledTimes.map((date) => moment(date));
            const min = moment.min(momentScheduledTimes.filter((moment) => moment.isAfter()));
            updateIn = min.diff(moment.now()) + 1000;
        }
        if (updateIn == null)
            return;
        const id = setTimeout(getOwnShifts, updateIn);
        return () => clearTimeout(id);
    }, [updateCounter]);
    if (areShiftsLoading) {
        return (React.createElement(HomeRoot, null,
            React.createElement(HomeItem, { gray: true })));
    }
    else {
        return (React.createElement(HomeRoot, null,
            React.createElement(Status, { nextScheduledShift: scheduledShifts[0], currentShift: currentShift, destroyShift: destroyShift }),
            !currentShift && React.createElement(FlexibleShift, { createShift: createShift }),
            (scheduledShifts.length > 0 || currentShift) && React.createElement(ScheduledShiftsPreview, { scheduledShifts: scheduledShifts })));
    }
}
