import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
export function useDisplayCustomerPrompts() {
    const [searchParams] = useSearchParams();
    const searchParamsStr = searchParams.toString() && `?${searchParams.toString()}`;
    const typeSearchParam = searchParams.get("type") || "";
    const isCustomer = ["customer", "existing-customer"].includes(typeSearchParam);
    const isExistingCustomer = typeSearchParam === "existing-customer";
    return { isCustomer, isExistingCustomer, searchParamsStr };
}
export function useShowForSomeTime(miliseconds = 2000) {
    const state = useState(false);
    const [show, setShow] = state;
    useEffect(() => {
        if (show) {
            const toRef = setTimeout(() => {
                setShow(false);
                clearTimeout(toRef);
            }, miliseconds);
        }
    }, [show]);
    return state;
}
