import { combineReducers } from "@reduxjs/toolkit";
import { configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import mobileSlice from "slices/mobile";
import shiftsSlice from "slices/shifts";
import userSlice from "slices/user";
import translatorSlice from "slices/translator";
import customerSlice from "slices/customer";
import userSessionSlice from "slices/userSession";
import visibilityDispatcherMiddleware from "./middleware/visibility";
const rootReducer = combineReducers({
    user: userSlice.reducer,
    customer: customerSlice.reducer,
    translator: translatorSlice.reducer,
    userSession: userSessionSlice.reducer,
    shifts: shiftsSlice.reducer,
    mobile: mobileSlice.reducer,
});
const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().prepend(visibilityDispatcherMiddleware),
});
export const useAppDispatch = useDispatch;
export default store;
