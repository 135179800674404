var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from "react";
import { useState } from "react";
import { Controller } from "react-hook-form";
import styled, { css } from "styled-components";
import { style as styleConfig } from "../../../config";
import Error from "./Error";
const inputHeights = styleConfig.inputs.height;
const desktopInputHeight = inputHeights.desktop;
const Container = styled("div") `
  display: inline-block;
  float: left;
  width: ${(p) => (p.width ? p.width : "100%")};
`;
const inputCss = css `
  height: 10vw;
  box-sizing: border-box;
  padding: 0 1rem;
  border: 1px solid ${styleConfig.colors.gray};
  line-height: ${({ hasValue }) => (hasValue ? "10vw" : "calc(10vw - 1rem)")};
  padding-top: ${({ hasValue }) => (hasValue ? "1rem" : "0")};
  width: ${(p) => (p.width ? p.width : "100%")};
  - &:focus {
    line-height: calc(10vw - 1rem);
    padding-top: 1rem;
  }

  ${({ error }) => error &&
    css `
      border-color: ${styleConfig.colors.primary};
    `} @media (${styleConfig.media.desktop}) {
    height: ${desktopInputHeight};
    line-height: ${({ hasValue }) => (hasValue ? `calc(${desktopInputHeight} - 1rem)` : desktopInputHeight)};
    padding-top: ${({ hasValue }) => (hasValue ? "1rem" : "0")};

    &:focus {
      line-height: calc(${desktopInputHeight} - 1rem);
      padding-top: 1rem;
    }
  }
`;
const Input = styled.input `
  ${inputCss}
`;
const Label = styled.label `
  height: 10vw;
  pointer-events: none;
  position: absolute;
  line-height: 10vw;
  padding-top: 0;
  padding-left: 1rem;
  color: ${styleConfig.colors.gray};
  transition: 0.1s ease-in-out;

  ${({ isFocused, hasValue }) => (isFocused || hasValue) &&
    css `
      transform: translateY(-1.25em);
      font-size: calc(4.5vw * 0.5);
    `} @media (${styleConfig.media.desktop}) {
    font-size: 1rem;
    height: ${desktopInputHeight};
    line-height: ${desktopInputHeight};

    ${({ isFocused, hasValue }) => (isFocused || hasValue) &&
    css `
        transform: translateY(-0.75rem);
        font-size: 0.75rem;
      `};
  }
`;
export default function InputField(props) {
    const { label, name, error, value, onChange, onBlur, onFocus, width, type = "text", required = false } = props, rest = __rest(props, ["label", "name", "error", "value", "onChange", "onBlur", "onFocus", "width", "type", "required"]);
    const [isFocused, setIsFocused] = useState(false);
    const handleFocus = (e) => {
        setIsFocused(true);
        onFocus && onFocus(e);
    };
    const handleBlur = (e) => {
        !value && setIsFocused(false);
        onBlur && onBlur(e);
    };
    const hasValue = !!value;
    const sharedProps = {
        id: name,
        onFocus: handleFocus,
        onBlur: handleBlur,
        name,
        error,
        hasValue,
        value,
        type,
        onChange,
        width,
    };
    return (React.createElement(Container, { width: width },
        React.createElement(Label, { hasValue: hasValue, isFocused: isFocused, htmlFor: name }, required ? `${label} *` : label),
        React.createElement(Input, Object.assign({}, sharedProps, rest)),
        error && React.createElement(Error, null, error)));
}
export function HookFormInput(props) {
    const { name, control } = props, rest = __rest(props, ["name", "control"]);
    return (React.createElement(Controller, { name: name, control: control, render: ({ field }) => React.createElement(InputField, Object.assign({}, rest, { name: field.name, value: field.value, onChange: field.onChange })) }));
}
