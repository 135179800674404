import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "reduxStore";
import { destroyUserSessionAction } from "slices/userSession";
import { selectUser } from "../../selectors/user";
import TranslatorHome from "./TranslatorHome";
import CustomerHome from "./CustomerHome";
export default function Home() {
    const user = useSelector(selectUser);
    const dispatch = useAppDispatch();
    if (!user)
        return null;
    useEffect(() => {
        if (!user.translatorId && !user.customerId) {
            dispatch(destroyUserSessionAction());
        }
    }, [user.translatorId, user.customerId]);
    if (user.translatorId) {
        return React.createElement(TranslatorHome, null);
    }
    else if (user.customerId) {
        return React.createElement(CustomerHome, null);
    }
    else {
        return null;
    }
}
