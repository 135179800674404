import * as React from "react";
import { Fragment, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import ahoy from "ahoy.js";
import { closeMenuDisplayAction } from "slices/mobile";
export default function LocationChange(props) {
    const location = useLocation();
    const dispatch = useDispatch();
    useEffect(() => {
        window.scrollTo(0, 0);
        ahoy.trackView();
        dispatch(closeMenuDisplayAction());
    }, [JSON.stringify(location)]);
    return React.createElement(Fragment, null, props.children);
}
