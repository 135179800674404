import styled from "styled-components";
import { style as styleConfig } from "config";
const Form = styled.form `
  width: 90%;

  @media (${styleConfig.media.desktop}) {
    width: 50%;
    max-width: 780px;
  }
`;
export default Form;
