import React, { Fragment } from "react";
import styled from "styled-components";
import { GridContainer, GridItem, HeadingGridItem } from "components/shared/layout/Grid";
import { formatDate } from "components/helpers/dateFormatting";
import { maxTwoDecimals } from "components/helpers/numberFormatting";
const InvoiceCallItemsOutsideFlatGridContainer = styled(GridContainer) `
  grid-template-columns: 5fr 5fr 4fr 3fr 3fr 4fr;

  @media print {
    page-break-before: avoid;
  }
`;
const InvoiceCallItemsGridItem = styled(GridItem) `
  margin-bottom: 0.5em;
`;
export default function InvoiceCallItemsGrid(props) {
    const { items, outsideFlat = false } = props;
    const Container = InvoiceCallItemsOutsideFlatGridContainer;
    const Item = InvoiceCallItemsGridItem;
    return (React.createElement(Container, null,
        React.createElement(HeadingGridItem, null,
            React.createElement("b", null, "Zeitpunkt")),
        React.createElement(HeadingGridItem, null,
            React.createElement("b", null, "Nummer")),
        React.createElement(HeadingGridItem, null,
            React.createElement("b", null, "Sprache")),
        React.createElement(HeadingGridItem, null,
            React.createElement("b", null, "Dauer (Sek.)")),
        React.createElement(HeadingGridItem, null,
            React.createElement("b", null, "Dauer (Min.)")),
        React.createElement(HeadingGridItem, null, outsideFlat && React.createElement("b", null, "\u2248 Dauer (Min.)")),
        items.map((i) => {
            const seconds = outsideFlat ? i.secondsOutsideFlat : i.secondsInFlat;
            return (React.createElement(Fragment, { key: i.id },
                React.createElement(Item, null, formatDate(i.call.createdAt)),
                React.createElement(Item, null, i.call.from),
                React.createElement(Item, null, i.call.languageDe),
                React.createElement(Item, null, seconds),
                React.createElement(Item, null, maxTwoDecimals(seconds / 60.0)),
                React.createElement(Item, null, outsideFlat && maxTwoDecimals(i.roundedSecondsOutsideFlat / 60.0))));
        })));
}
