import * as React from "react";
import styled from "styled-components";
import moment from "moment";
import { some } from "lodash-es";
import { DayItem, ShiftItem, DateHeader } from "../../shared/Shift";
import Paragraph from "../../shared/primitives/Paragraph";
import { formatDayLong } from "../../helpers/dateFormatting";
import { mapShiftsToDays } from "../../helpers/shifts";
import { style as styleConfig } from "../../../config";
const { media } = styleConfig;
const Root = styled.div `
  box-sizing: border-box;
  padding: 1em 1em 1em 1em;
  width: 100%;
  text-align: left;

  @media (${media.desktop}) {
    padding: 3em 6em;
  }
`;
export default function ShiftsList(props) {
    const { currentShift, selectedShifts, shifts, handleChange, noShiftsHint, endOfSchedule, currentDay } = props;
    const shiftsByDay = mapShiftsToDays(shifts);
    const currentDayMoment = moment(currentDay, "YYYYDDD");
    const daysOfWeek = [0, 1, 2, 3, 4, 5, 6].map((delta) => currentDayMoment.clone().add(delta, "days"));
    const daysWithShifts = daysOfWeek.map((day) => day.format("YYYYDDD")).filter((day) => shiftsByDay[day]);
    const hasOnlyUnscheduledDays = endOfSchedule && currentDayMoment.isAfter(endOfSchedule);
    const containsUnscheduledDays = endOfSchedule && some(daysOfWeek, (day) => endOfSchedule.isBefore(day));
    return (React.createElement(Root, null,
        daysWithShifts.length > 0 ? (daysWithShifts.map((key) => (React.createElement(DayItem, { key: key },
            React.createElement(DateHeader, { color: "fontGray" }, formatDayLong(moment(key, "YYYYDDD"))),
            shiftsByDay[key].map((shift, key) => {
                const checked = !!selectedShifts.find((id) => id === shift.id);
                return (React.createElement(ShiftItem, { key: key, active: currentShift ? currentShift.startsAt === shift.startsAt : false, shift: shift, checked: checked, onChange: handleChange }));
            }))))) : hasOnlyUnscheduledDays ? null : (React.createElement(Paragraph, null, noShiftsHint)),
        containsUnscheduledDays && (React.createElement("div", null,
            React.createElement(Paragraph, null, "Du kannst Schichten w\u00E4hrend der n\u00E4chsten 14 Tage ausw\u00E4hlen"),
            React.createElement(Paragraph, null, "Wir schalten die Schichten immer 14 Tage vorher zum Eintragen frei. Komm regelm\u00E4\u00DFig wieder um dich f\u00FCr Schichten einzutragen.")))));
}
