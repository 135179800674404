import * as React from "react";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import moment from "moment";
import { Link } from "react-router-dom";
import { selectAreShiftsLoading, selectCurrentShift, selectScheduledShifts } from "../../selectors/shift";
import { H1, H2 } from "../shared/primitives/Heading";
import Icon from "../shared/primitives/Icon";
import Paragraph from "../shared/primitives/Paragraph";
import FlexContainer from "../shared/layout/FlexContainer";
import FlexItem from "../shared/layout/FlexItem";
import DateControls from "../shared/DateControls";
import ShiftsList from "./presentational/ShiftsList";
import SnappyControls from "./presentational/SnappyControls";
import { style as styleConfig, routes } from "../../config";
import { useAppDispatch } from "reduxStore";
import { getOwnShiftsAction, unassignShiftsAction } from "slices/shifts";
const { colors, media } = styleConfig;
const Root = styled(FlexContainer) `
  flex-direction: column;
`;
const HeadingItem = styled(FlexItem) `
  background-color: ${colors.primary};
`;
const Teaser = styled(FlexItem) `
  display: flex;
  align-items: center;
`;
const MainContent = styled(FlexItem) `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: ${colors.lightGray};
`;
const MainContentHeading = styled(H2) `
  align-self: center;

  @media (${media.desktop}) {
    align-self: flex-start;
  }
`;
const LinkContainer = styled.div `
  text-align: center;
  width: 100%;
  margin-bottom: 1rem;
`;
export default function ShiftsPlan() {
    const dispatch = useAppDispatch();
    const startOfWeek = moment().startOf("week").clone();
    const [currentDay, setCurrentDay] = useState(startOfWeek);
    const [selectedShifts, setSelectedShifts] = useState([]);
    const getOwnShifts = () => dispatch(getOwnShiftsAction());
    const unassignShifts = (ids) => dispatch(unassignShiftsAction(ids));
    const shifts = useSelector(selectScheduledShifts);
    const currentShift = useSelector(selectCurrentShift);
    const isLoadingShifts = useSelector(selectAreShiftsLoading);
    useEffect(() => {
        getOwnShifts();
    }, []);
    const handleShiftToggle = (event) => {
        const { target } = event;
        let newShifts;
        if (target.checked) {
            newShifts = selectedShifts.concat(target.value);
        }
        else {
            newShifts = selectedShifts.filter((id) => id != target.value);
        }
        setSelectedShifts(newShifts);
    };
    const handleDeselectAll = () => setSelectedShifts([]);
    const handleUnassignShifts = (ids) => {
        unassignShifts(ids).then(getOwnShifts);
        handleDeselectAll();
    };
    const saveButtonContent = () => {
        const SaveButton = styled.span `
      display: flex;
      align-items: center;
    `;
        return (React.createElement(SaveButton, null,
            React.createElement(Icon, { color: "primary", icon: "trash" }),
            " Absagen"));
    };
    return (React.createElement(Root, null,
        React.createElement(HeadingItem, { column: 1 / 1 },
            React.createElement(H1, { solo: true, color: "secondary" }, "Deine Schichten")),
        React.createElement(Teaser, { column: 1 / 1 },
            React.createElement(Icon, { color: "black", medium: true, icon: "calendar" }),
            React.createElement(Paragraph, null, "Hier siehst du deine Schichten. Bei Bedarf kannst du sie absagen.")),
        React.createElement(MainContent, { column: 1 / 1, padding: "3em 0" },
            React.createElement(MainContentHeading, null, "Deine n\u00E4chsten Schichten"),
            React.createElement(LinkContainer, null,
                React.createElement(Link, { to: routes.root() }, "Zur\u00FCck zur \u00DCbersicht")),
            React.createElement(DateControls, { disabled: selectedShifts.length > 0, currentDay: currentDay, setCurrentDay: setCurrentDay, start: startOfWeek }),
            !isLoadingShifts && (React.createElement(ShiftsList, { shifts: shifts, selectedShifts: selectedShifts, currentShift: currentShift, currentDay: currentDay.format("YYYYDDD"), handleChange: handleShiftToggle, noShiftsHint: "Du hast f\u00FCr diese Woche noch keine Schicht geplant" }))),
        React.createElement(SnappyControls, { buttonInverse: true, handleSave: handleUnassignShifts, saveButtonContent: saveButtonContent(), handleDeselectAll: handleDeselectAll, shiftIds: selectedShifts })));
}
