import * as React from "react";
import FlexItem from "../../shared/layout/FlexItem";
import { H2 } from "../../shared/primitives/Heading";
export default function Section(props) {
    const { children, backgroundColor, desktopHalf, horizontalPadding, verticalPadding, desktopCentered, headingText, className, style, } = props;
    const desktopProps = {
        column: desktopHalf ? 1 / 2 : 1 / 1,
        padding: `${verticalPadding || "3em"} ${horizontalPadding || (desktopHalf ? "10%" : "22.5%")}`,
        textAlign: desktopCentered ? "center" : "left",
    };
    return (React.createElement(FlexItem, { className: className, backgroundColor: backgroundColor, column: 1 / 1, desktop: desktopProps, style: style },
        headingText && React.createElement(H2, null, headingText),
        children));
}
