var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getTranslatorCreditNote, getTranslatorCreditNotes, getTranslatorStats, } from "api/translator";
import { resetAppStateAction } from "actions/application";
const initialState = {
    stats: { isLoading: false, models: [] },
    creditNotes: { loading: false, data: null },
    creditNote: { loading: false, data: null },
};
export const getTranslatorStatsAction = createAsyncThunk("translator/getStats", () => __awaiter(void 0, void 0, void 0, function* () { return yield getTranslatorStats(); }));
export const getTranslatorCreditNotesAction = createAsyncThunk("customer/getInvoices", (params) => __awaiter(void 0, void 0, void 0, function* () { return yield getTranslatorCreditNotes(params); }));
export const getTranslatorCreditNoteAction = createAsyncThunk("customer/getInvoice", (params) => __awaiter(void 0, void 0, void 0, function* () { return yield getTranslatorCreditNote(params); }));
const setTranslatorCreditNotes = (state, action) => {
    state.creditNotes.data = action.payload;
    state.creditNotes.loading = false;
};
const setTranslatorCreditNote = (state, action) => {
    state.creditNote.data = action.payload;
    state.creditNote.loading = false;
};
export default createSlice({
    name: "translator",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getTranslatorStatsAction.pending, (state) => {
            state.stats.isLoading = true;
            state.stats.models = [];
        })
            .addCase(getTranslatorStatsAction.fulfilled, (state, action) => {
            state.stats.isLoading = false;
            state.stats.models = action.payload;
        })
            .addCase(getTranslatorStatsAction.rejected, (state) => {
            state.stats.isLoading = false;
            state.stats.models = [];
        })
            .addCase(getTranslatorCreditNotesAction.fulfilled, setTranslatorCreditNotes)
            .addCase(getTranslatorCreditNotesAction.pending, ({ creditNotes }) => {
            creditNotes.loading = true;
        })
            .addCase(getTranslatorCreditNotesAction.rejected, ({ creditNotes }) => {
            creditNotes.loading = false;
        })
            .addCase(getTranslatorCreditNoteAction.fulfilled, setTranslatorCreditNote)
            .addCase(getTranslatorCreditNoteAction.pending, ({ creditNote }) => {
            creditNote.loading = true;
        })
            .addCase(getTranslatorCreditNoteAction.rejected, ({ creditNote }) => {
            creditNote.loading = false;
        })
            .addCase(resetAppStateAction, () => initialState);
    },
});
