import moment from "moment";
export function formatDayShort(date, options) {
    const { includeYear = false } = options || {};
    const formatStr = includeYear ? "DD. MMM YYYY" : "DD. MMM";
    return moment(date).format(formatStr);
}
export function formatDayLong(date) {
    return moment(date).format("dddd, DD. MMM");
}
function secondsRemaining(endsAt) {
    const end = moment(endsAt);
    const secondsRemaining = end.diff(moment.now(), "s");
    return moment.duration(secondsRemaining, "s");
}
export function formatTimeRemaining(endsAt) {
    const duration = secondsRemaining(endsAt);
    return duration.format("y[y] d[d] h[h] m[m] s[s]").replace(/ 0[a-z]| [0-9]{1,2}s/g, "");
}
export function formatHighestUnitRemaining(endsAt) {
    const remaining = secondsRemaining(endsAt).format("y:[y] d:[d] h:[h] m:[m]");
    const highestUnit = remaining.split(" ")[0];
    const [value, unit] = highestUnit.split(":");
    const valueInt = parseInt(value);
    let finalUnit = unit;
    finalUnit = finalUnit.replace("y", ` Jahr${valueInt > 1 ? "e" : ""}`);
    finalUnit = finalUnit.replace("d", ` Tag${valueInt > 1 ? "e" : ""}`);
    finalUnit = finalUnit.replace("h", ` Stund${valueInt > 1 ? "en" : "e"}`);
    finalUnit = finalUnit.replace("m", ` Minut${valueInt > 1 ? "en" : "e"}`);
    return [value, finalUnit];
}
export function formatWeekspan(day) {
    const startOfWeek = day.startOf("week");
    if (startOfWeek.isSame(moment().startOf("week"))) {
        return "Diese Woche";
    }
    else {
        return `${formatDayShort(startOfWeek)} bis ${formatDayShort(startOfWeek.clone().add(6, "days"))}`;
    }
}
export function formatMonth(day) {
    const startOfMonth = day.startOf("month");
    if (startOfMonth.isSame(moment().startOf("month"))) {
        return "Dieser Monat";
    }
    else {
        return `${moment(startOfMonth).format("MMMM")} ${formatYear(startOfMonth)}`;
    }
}
export function getTime(date) {
    if (moment(date).format("mm") === "00") {
        return moment(date).format("H");
    }
    else {
        return moment(date).format("H:mm");
    }
}
export function formatYear(date) {
    return moment(date).format("YYYY");
}
export function formatTime(date) {
    return `${getTime(date)} Uhr`;
}
export function formatTimespan(startsAt, endsAt) {
    return `${getTime(startsAt)} - ${getTime(endsAt)} Uhr`;
}
export function formatDate(date) {
    return moment(date).format("DD.MM.YYYY, HH:mm");
}
export function formatWeekday(date) {
    return moment(date).format("dddd");
}
export function formatDayWithLongMonth(date) {
    return moment(date).format("DD. MMMM");
}
