import { createSlice } from "@reduxjs/toolkit";
import { resetAppStateAction } from "actions/application";
const initialState = { displayMenu: false };
const mobileSlice = createSlice({
    name: "mobile",
    initialState,
    reducers: {
        openMenuDisplayAction(state) {
            state.displayMenu = true;
        },
        closeMenuDisplayAction(state) {
            state.displayMenu = false;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(resetAppStateAction, () => initialState);
    },
});
export const { openMenuDisplayAction, closeMenuDisplayAction } = mobileSlice.actions;
export default mobileSlice;
