var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { activateUser, getUser, resetPassword, sendResetPasswordLink, updateUser, } from "api/user";
import { initialLoadAction, resetAppStateAction } from "actions/application";
import { setLoginInfoAction } from "./userSession";
const initialState = { isLoading: false, model: null };
export const getUserAction = createAsyncThunk("user/get", () => __awaiter(void 0, void 0, void 0, function* () { return yield getUser(); }));
export const activateUserAction = createAsyncThunk("user/activate", (data, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield activateUser(data);
    thunkAPI.dispatch(initialLoadAction());
    return response;
}));
export const updateUserAction = createAsyncThunk("user/update", (params) => __awaiter(void 0, void 0, void 0, function* () { return yield updateUser(params); }));
export const sendResetPasswordLinkAction = createAsyncThunk("user/sendResetPasswordLink", (props, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const { email, isCustomer } = props;
    yield sendResetPasswordLink(email);
    let loginInfo = `Wir haben eine E-Mail zum Zurücksetzen deines Passworts an ${email} gesendet.`;
    if (isCustomer) {
        loginInfo = `Wir haben eine E-Mail zum Zurücksetzen ihres Passworts an ${email} gesendet.`;
    }
    thunkAPI.dispatch(setLoginInfoAction(loginInfo));
}));
export const resetPasswordAction = createAsyncThunk("user/resetPasswordAction", (data, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    yield resetPassword(data);
    const loginInfo = "Dein Passwort wurde geändert. Bitte melde dich erneut an.";
    thunkAPI.dispatch(setLoginInfoAction(loginInfo));
}));
const setUser = (state, action) => {
    state.model = action.payload;
    state.isLoading = false;
};
export default createSlice({
    name: "user",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getUserAction.pending, (state) => (Object.assign(Object.assign({}, state), { isLoading: true })))
            .addCase(getUserAction.rejected, (state) => (Object.assign(Object.assign({}, state), { isLoading: false })))
            .addCase(getUserAction.fulfilled, setUser)
            .addCase(activateUserAction.fulfilled, setUser)
            .addCase(updateUserAction.fulfilled, setUser)
            .addCase(resetPasswordAction.fulfilled, (state) => {
            state.model = null;
        })
            .addCase(resetAppStateAction, () => initialState);
    },
});
