import React, { Fragment, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import validator from "validator";
import { activateUserAction } from "slices/user";
import { style as styleConfig, externalLinks, routes } from "../config";
const { colors } = styleConfig;
import FlexContainer from "./shared/layout/FlexContainer";
import FlexItem from "./shared/layout/FlexItem";
import Paragraph from "./shared/primitives/Paragraph";
import InputField from "./shared/primitives/InputField";
import Checkbox from "./shared/primitives/Checkbox";
import Error from "./shared/primitives/Error";
import Button from "./shared/primitives/Button";
import Title from "./shared/layout/Title";
import { useAppDispatch } from "reduxStore";
import { useDisplayCustomerPrompts } from "customHooks";
const Root = styled(FlexContainer) `
  flex-direction: column;
`;
const MainContainer = styled(FlexItem) `
  background-color: ${colors.lightGray};
`;
const SubmitButton = styled(Button) `
  margin-top: 1em;
  width: 80%;
`;
const InputWrapper = styled.div `
  width: 100%;
  margin-bottom: 1em;
`;
const Form = styled.form `
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Label = styled.label `
  display: flex;
  align-items: center;

  span {
    margin-right: 0.5em;
  }
`;
const CheckboxText = styled.span `
  flex-shrink: 5;
`;
const ENSURE_INTENTIONAL_RECORDING_PHRASE = "YES, TURN ON RECORDINGS!!!";
export default function UserActivation() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { activationToken } = useParams();
    const { isCustomer, isExistingCustomer, searchParamsStr } = useDisplayCustomerPrompts();
    const initialValues = {
        password: "",
        passwordConfirmation: "",
        tos: null,
        dpa: null,
        confidentiality: null,
        taxPaymentConsent: null,
        callRecordingPermit: null,
    };
    const initialErrors = {
        password: null,
        passwordConfirmation: null,
        tos: null,
        dpa: null,
        confidentiality: null,
        taxPaymentConsent: null,
        callRecordingPermit: null,
    };
    const initialTouched = {
        password: false,
        passwordConfirmation: false,
        tos: false,
        dpa: false,
        confidentiality: false,
        taxPaymentConsent: false,
        callRecordingPermit: false,
    };
    const [state, setState] = useState({
        values: Object.assign({}, initialValues),
        touched: Object.assign({}, initialTouched),
        errors: Object.assign({}, initialErrors),
    });
    const handleChange = (event) => {
        const { target } = event;
        const { name, checked, type, value: eventValue } = target;
        const { values, touched } = state;
        let newValue;
        if (type === "checkbox" && !checked) {
            newValue = null;
        }
        else {
            newValue = eventValue;
        }
        setState(Object.assign(Object.assign({}, state), { values: Object.assign(Object.assign({}, values), { [name]: newValue }), errors: validate(Object.assign(Object.assign({}, values), { [name]: newValue })), touched: Object.assign(Object.assign({}, touched), { [name]: true }) }));
    };
    const onSubmit = (event) => {
        event.preventDefault();
        const { password, tos, dpa, confidentiality, taxPaymentConsent, callRecordingPermit } = state.values;
        if (activationToken) {
            dispatch(activateUserAction({
                activationToken,
                password,
                tos,
                dpa,
                confidentiality,
                taxPaymentConsent,
                callRecordingPermit,
            }));
            navigate(routes.login(searchParamsStr));
        }
    };
    const callRecordingEnabled = () => {
        return process.env.INCOMING_CALL_RECORDINGS == ENSURE_INTENTIONAL_RECORDING_PHRASE;
    };
    const validate = (values) => {
        const { password, passwordConfirmation } = values;
        let checkboxFields = [];
        if (isCustomer) {
            if (!isExistingCustomer) {
                checkboxFields = checkboxFields.concat("tos", "dpa");
            }
        }
        else {
            checkboxFields = checkboxFields.concat("tos", "confidentiality", "taxPaymentConsent");
            if (callRecordingEnabled()) {
                checkboxFields = checkboxFields.concat("callRecordingPermit");
            }
        }
        const errors = Object.assign({}, initialErrors);
        if (!validator.isLength(password, { min: 8 })) {
            errors.password = "Muss mindestens acht Zeichen haben";
        }
        if (!validator.isLength(passwordConfirmation, { min: 8 })) {
            errors.passwordConfirmation = "Muss mindestens acht Zeichen haben";
        }
        else if (!validator.equals(password, passwordConfirmation)) {
            errors.passwordConfirmation = "Muss mit dem Passwort übereinstimmen";
        }
        checkboxFields.forEach((attributeName) => {
            if (!values[attributeName]) {
                errors[attributeName] = "Muss akzeptiert werden";
            }
        });
        return errors;
    };
    const { values, touched, errors } = state;
    let setPasswordPrompt = "Lege ein Passwort fest. Mit diesem kannst du dich später bei uns anmelden.";
    if (isCustomer) {
        setPasswordPrompt = "Legen Sie ein Passwort fest. Mit diesem können Sie sich später anmelden.";
    }
    return (React.createElement(Root, null,
        React.createElement(Title, null, "Passwort anlegen"),
        React.createElement(MainContainer, { column: 1 / 1 },
            React.createElement(Paragraph, null, setPasswordPrompt),
            React.createElement(Form, { onSubmit: onSubmit },
                React.createElement(InputWrapper, null,
                    React.createElement(InputField, { value: values.password, label: "Passwort", type: "password", name: "password", onChange: handleChange, error: touched.password && errors.password })),
                React.createElement(InputWrapper, null,
                    React.createElement(InputField, { value: values.passwordConfirmation, label: "Passwort best\u00E4tigen", type: "password", name: "passwordConfirmation", onChange: handleChange, error: touched.passwordConfirmation && errors.passwordConfirmation })),
                !isExistingCustomer && (React.createElement(InputWrapper, null,
                    React.createElement(Label, { htmlFor: "tos" },
                        React.createElement(Checkbox, { onChange: handleChange, checked: !!values.tos, name: "tos", value: "1", id: "tos" }),
                        React.createElement(CheckboxText, null,
                            "Ich akzeptiere die",
                            " ",
                            React.createElement("a", { href: isCustomer ? externalLinks.customerTos : externalLinks.tos, target: "_blank", rel: "noopener noreferrer" }, "Allgemeinen Gesch\u00E4ftsbedingungen"),
                            ".")),
                    touched.tos && errors.tos && React.createElement(Error, null, errors.tos))),
                isCustomer && !isExistingCustomer && (React.createElement(InputWrapper, null,
                    React.createElement(Label, null,
                        React.createElement(Checkbox, { onChange: handleChange, checked: !!values.dpa, name: "dpa", value: "1" }),
                        React.createElement(CheckboxText, null,
                            "Ich akzeptiere den",
                            " ",
                            React.createElement("a", { href: externalLinks.dpa, target: "_blank", rel: "noopener noreferrer" }, "Auftragsverarbeitungsvertrag"),
                            ".")),
                    touched.dpa && errors.dpa && React.createElement(Error, null, errors.dpa))),
                !isCustomer && (React.createElement(Fragment, null,
                    React.createElement(InputWrapper, null,
                        React.createElement(Label, null,
                            React.createElement(Checkbox, { onChange: handleChange, checked: !!values.confidentiality, name: "confidentiality", value: "1" }),
                            React.createElement(CheckboxText, null,
                                "Ich verpflichte mich zur Einhaltung der",
                                " ",
                                React.createElement("a", { href: externalLinks.confidentiality, target: "_blank", rel: "noopener noreferrer" }, "Schweigepflicht"),
                                ".")),
                        touched.confidentiality && errors.confidentiality && React.createElement(Error, null, errors.confidentiality)),
                    React.createElement(InputWrapper, null,
                        React.createElement(Label, null,
                            React.createElement(Checkbox, { onChange: handleChange, checked: !!values.taxPaymentConsent, name: "taxPaymentConsent", value: "1" }),
                            React.createElement(CheckboxText, null,
                                "Ich habe die",
                                " ",
                                React.createElement("a", { href: externalLinks.taxPaymentConsent, target: "_blank", rel: "noopener noreferrer" }, "Hinweise zur Versteuerung"),
                                " ",
                                "zur Kenntnis genommen.")),
                        touched.taxPaymentConsent && errors.taxPaymentConsent && React.createElement(Error, null, errors.taxPaymentConsent)))),
                !isCustomer && callRecordingEnabled() && (React.createElement(InputWrapper, null,
                    React.createElement(Label, null,
                        React.createElement(Checkbox, { onChange: handleChange, checked: !!values.callRecordingPermit, name: "callRecordingPermit", value: "1" }),
                        React.createElement(CheckboxText, null,
                            "Ich bin damit einverstanden, dass die Gespr\u00E4che zu Forschungszwecken aufgezeichnet und ausgewertet werden. Mehr dazu und zum Projekt findest du ",
                            React.createElement("a", { href: externalLinks.callRecordingPermit }, "hier"))),
                    touched.callRecordingPermit && errors.callRecordingPermit && React.createElement(Error, null, errors.callRecordingPermit))),
                React.createElement(SubmitButton, { type: "submit", disabled: !Object.values(values).find((v) => !!v) || !!Object.values(errors).find((e) => e !== null) }, "Anlegen")))));
}
