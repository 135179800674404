var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { style as styleConfig } from "../../../config";
const { colors } = styleConfig;
const buttonStyles = css `
  line-height: ${(p) => (p.slim ? "1rem" : "2rem")};
  width: max-content;
  border: ${(p) => (p.border ? `1px solid ${p.border}` : "0")};
  display: inline-block;
  background-color: ${(p) => (p.inverse ? colors.secondary : colors.primary)};
  color: ${(p) => (p.color || p.inverse ? colors.primary : colors.secondary)};
  padding: ${(p) => (p.slim ? "0.5em 1rem" : "0.5em 4rem")};
  text-align: center;
  font-weight: bold;
  vertical-align: top;
  cursor: pointer;
  ${({ marginLeft }) => marginLeft &&
    css `
      margin-left: ${marginLeft};
    `};
  ${({ noFloat }) => !noFloat &&
    css `
      float: left;
    `}
  &:disabled {
    cursor: not-allowed;

    ${({ inverse }) => inverse &&
    css `
        color: ${colors.gray};
        background-color: ${colors.white};
        border: ${(p) => (p.border ? `1px solid ${colors.gray}` : "0")};
      `}

    ${({ inverse }) => !inverse &&
    css `
        color: ${colors.white};
        background-color: ${colors.gray};
      `}
  }
  ${({ backgroundColor }) => backgroundColor &&
    css `
      background-color: ${backgroundColor};
    `};
  ${({ rounded }) => rounded &&
    css `
      border-radius: 5px;
    `};
  }

  @media (${styleConfig.media.desktop}) {
    line-height: ${(p) => (p.slim ? "2rem" : styleConfig.inputs.height.desktop)};
    ${({ desktopOffset }) => desktopOffset &&
    css `
        margin-left: calc(50px + 1em);
      `};
  }
`;
export const ButtonAsLink = styled(
/* eslint-disable @typescript-eslint/no-unused-vars */
(_a) => {
    var { marginLeft, inverse, border, auto, noFloat, backgroundColor, rounded, desktopOffset } = _a, rest = __rest(_a, ["marginLeft", "inverse", "border", "auto", "noFloat", "backgroundColor", "rounded", "desktopOffset"]);
    return React.createElement(Link, Object.assign({}, rest));
}) `
  ${buttonStyles};
`;
export const ButtonAsHref = styled((_a) => {
    var { marginLeft, inverse, border, auto, noFloat, backgroundColor, rounded, desktopOffset } = _a, rest = __rest(_a, ["marginLeft", "inverse", "border", "auto", "noFloat", "backgroundColor", "rounded", "desktopOffset"]);
    return React.createElement("a", Object.assign({ rel: "noopener noreferrer", target: "_blank" }, rest));
}).attrs({ as: "a" }) `
  ${buttonStyles};
`;
const StyledButton = styled("button") `
  ${buttonStyles};
`;
export default StyledButton;
