import * as React from "react";
import styled from "styled-components";
import FlexItem from "../../shared/layout/FlexItem";
import { H1 } from "../../shared/primitives/Heading";
import { style as styleConfig } from "../../../config";
const Heading = styled(H1) `
  hyphens: manual;
  color: ${({ color }) => color};

  @media (${styleConfig.media.desktop}) {
    hyphens: ${({ desktopHyphens }) => (desktopHyphens ? "manual" : "none")};
  }
`;
export default function Title(props) {
    const { inverse, renderChildrenOnly, children } = props;
    let backgroundColor = styleConfig.colors.primary;
    let textColor = styleConfig.colors.secondary;
    if (inverse) {
        backgroundColor = styleConfig.colors.secondary;
        textColor = styleConfig.colors.primary;
    }
    return (React.createElement(FlexItem, { backgroundColor: backgroundColor, column: 1 / 1, desktop: { padding: "4em 10%", textAlign: "center" } }, renderChildrenOnly ? (children) : (React.createElement(Heading, { solo: true, color: textColor }, children))));
}
