import styled from "styled-components";
import { style as styleConfig } from "config";
import FlexContainer from "../layout/FlexContainer";
const SideBySideInputs = styled(FlexContainer) `
  flex-direction: column;
  gap: 1rem;
  align-items: flex-start;
  width: 100%;

  @media (${styleConfig.media.desktop}) {
    flex-direction: row;
  }
`;
export default SideBySideInputs;
