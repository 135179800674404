var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { useFormContext } from "react-hook-form";
import { HookFormInput } from "components/shared/primitives/InputField";
import FlexContainer from "../layout/FlexContainer";
export default function FormInput(props) {
    const { name } = props, rest = __rest(props, ["name"]);
    const { control, formState: { errors: formErrors }, } = useFormContext();
    const errors = formErrors;
    let error = errors;
    name.split(".").forEach((segment) => {
        if (!error)
            return;
        error = error[segment];
    });
    return (React.createElement(FlexContainer, { alignItems: "flex-start", flexDirection: "column", fullWidth: true },
        React.createElement(HookFormInput, Object.assign({ control: control, name: name, error: error === null || error === void 0 ? void 0 : error.message }, rest))));
}
