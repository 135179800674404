import moment from "moment";
import { mapValues, sortBy } from "lodash-es";
export function mapShiftsToDays(shifts) {
    const initialShifts = {};
    const shiftsByDay = shifts.reduce((allShifts, currentShift) => {
        const key = moment(currentShift.startsAt).format("YYYYDDD");
        if (!allShifts[key]) {
            allShifts[key] = [currentShift];
        }
        else {
            allShifts[key] = allShifts[key].concat(currentShift);
        }
        return allShifts;
    }, initialShifts);
    return mapValues(shiftsByDay, (shifts) => sortBy(shifts, ["startsAt"]));
}
export function mapShiftsToWeeks(shifts) {
    const shiftsByDay = mapShiftsToDays(shifts);
    const shiftsByWeek = {};
    Object.keys(shiftsByDay).forEach((dayKey) => {
        const day = moment(dayKey, "YYYYDDD");
        const weekKey = `${day.year()}${day.week()}`;
        if (shiftsByWeek[weekKey]) {
            shiftsByWeek[weekKey][dayKey] = shiftsByDay[dayKey];
        }
        else {
            shiftsByWeek[weekKey] = { [dayKey]: shiftsByDay[dayKey] };
        }
    });
    return shiftsByWeek;
}
