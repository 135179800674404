import React, { Fragment } from "react";
import { HomeItem } from "./Home/HomeItem";
import { H2 } from "./shared/primitives/Heading";
import Paragraph from "./shared/primitives/Paragraph";
import { ButtonAsHref } from "./shared/primitives/Button";
import { externalLinks } from "config";
function TeachingMaterialView() {
    return (React.createElement(Fragment, null,
        React.createElement(HomeItem, null,
            React.createElement(H2, { color: "primary" }, "Schulungsvideos"),
            React.createElement(Paragraph, null, "Hier findest Du eine \u00DCbersicht \u00FCber alle Schulungsvideos."),
            React.createElement(ButtonAsHref, { href: externalLinks.videos, noFloat: true }, "Videos anzeigen")),
        React.createElement(HomeItem, { gray: true },
            React.createElement(H2, { color: "primary" }, "Vokabellisten"),
            React.createElement(Paragraph, null, "Hier findest Du eine \u00DCbersicht \u00FCber alle Vokabellisten."),
            React.createElement(ButtonAsHref, { href: externalLinks.vocabulary, noFloat: true }, "Glossare anzeigen"))));
}
export default function TeachingMaterial() {
    return React.createElement(TeachingMaterialView, null);
}
