import { camelCase } from "lodash";
const products = ["triaphon", "hykist"];
export const product = products.find((p) => p === camelCase(process.env.PRODUCT || "triaphon")) || "triaphon";
const themes = {
    triaphon: {
        colors: {
            primary: "#ED1D24",
            secondary: "#FFFFFF",
        },
    },
    hykist: {
        colors: {
            primary: "#2172BE",
            secondary: "#CCE8F3",
        },
    },
};
const theme = themes[product] || themes.triaphon;
const colors = {
    primary: theme.colors.primary,
    secondary: theme.colors.secondary,
    white: "#FFFFFF",
    lightGray: "#F2F2F2",
    gray: "#A4A1A1",
    darkGray: "#333333",
    black: "#000000",
    fontGray: "#4A4A4A",
    fontGrayDark: "#C4C4C4",
    facebookBlue: "#3B5998",
    linkedInBlue: "#0072B1",
    green: "#00A02A",
    red: "#ED1D24",
};
export const style = {
    colors,
    media: {
        desktop: "min-width: 1024px",
        full: "min-width: 1280px",
    },
    inputs: {
        height: {
            desktop: "53px",
        },
    },
    zIndex: {
        Header: {
            Navigation: 1000,
        },
        About: {
            Hospitals: 100,
            ScrollDownHelper: 100,
        },
    },
};
function route(routeName) {
    return (params = "") => `${routeName}${params}`;
}
export const routes = {
    managePhoneNumbers: route("/telefonnummern"),
    manageAddress: route("/addresse"),
    registerCustomer: route("/registrieren"),
    forgotPassword: route("/passwort-vergessen"),
    login: route("/login"),
    ownShifts: route("/deine-schichten"),
    planShifts: route("/plan"),
    root: route("/"),
    customerStats: route("/statistik"),
    customerInvoices: route("/rechnungen"),
    customerInvoice: route("/rechnung"),
    translatorStats: route("/deine-zeiten"),
    translatorCreditNotes: route("/gutschriften"),
    translatorCreditNote: route("/gutschrift"),
    paymentModel: route("/zahlungsmodell"),
    events: route("/termine"),
    teachingMaterial: route("/schulungsmaterial"),
};
export const publicRoutes = [routes.registerCustomer(), routes.forgotPassword(), routes.login()];
function landingPageUrl(path) {
    const landingPageRootUrl = process.env.LANDING_PAGE || "https://www.triaphon.org";
    return `${landingPageRootUrl}${path}`;
}
export const externalLinks = {
    advisoryBoard: landingPageUrl("/beirat"),
    callRecordingPermit: landingPageUrl("/einverstaendniserklaerung/"),
    confidentiality: landingPageUrl("/schweigepflicht/"),
    customerTos: landingPageUrl("/allgemeine-geschaeftsbedingungen-kunden/"),
    dpa: landingPageUrl("/avv/"),
    events: landingPageUrl("/termine"),
    facebook: "https://www.facebook.com/triaphon",
    linkedIn: "https://www.linkedin.com/company/triaphon",
    imprint: landingPageUrl("/impressum"),
    mainLandingPage: landingPageUrl(""),
    privacyPolicy: landingPageUrl("/datenschutzerklaerung/"),
    taxPaymentConsent: landingPageUrl("/hinweise-zur-versteuerung/"),
    teachingMaterial: landingPageUrl("/schulungsmaterial/"),
    tos: landingPageUrl("/allgemeine-geschaeftsbedingungen"),
    videos: landingPageUrl("/videos"),
    vocabulary: landingPageUrl("/vokabeln"),
};
