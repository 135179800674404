import React, { Fragment, useEffect } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "reduxStore";
import { useParams } from "react-router-dom";
import { selectCustomerInvoice } from "selectors/customer";
import { getCustomerInvoiceAction } from "slices/customer";
import styled from "styled-components";
import { GridContainer, GridItem, HeadingGridItem } from "components/shared/layout/Grid";
import FlexContainer from "components/shared/layout/FlexContainer";
import Spinner from "components/shared/Spinner";
import Paragraph from "components/shared/primitives/Paragraph";
import { H2, H3, H4 } from "components/shared/primitives/Heading";
import { unitsTranslations } from "components/helpers/translations";
import Link from "components/shared/primitives/Link";
import InvoiceCallItemsGrid from "./InvoiceCallItemsGrid";
const NoPageBreakFlexContainer = styled(FlexContainer) `
  break-inside: avoid;
`;
const PrintInvisibleFlexContainer = styled(FlexContainer) `
  @media print {
    display: none;
  }
`;
const PositionsGridContainer = styled(GridContainer) `
  grid-template-columns: 2fr 4fr 3fr 2fr;
`;
const PrintInvisibleLink = styled(Link) `
  @media print {
    display: none;
  }
`;
export default function CustomerInvoice() {
    const dispatch = useAppDispatch();
    const invoiceState = useSelector(selectCustomerInvoice);
    const { invoiceId } = useParams();
    const { data: invoice, loading } = invoiceState;
    const printPage = () => {
        if (window.print) {
            window.print();
        }
    };
    useEffect(() => {
        if (!invoiceId)
            return;
        const params = { id: invoiceId };
        dispatch(getCustomerInvoiceAction(params));
    }, [invoiceId]);
    if (loading || !invoice) {
        return (React.createElement(FlexContainer, { flexDirection: "column", fullHeight: true },
            loading && React.createElement(Spinner, { color: "primary" }),
            !loading && !invoice && React.createElement(Paragraph, null, "Rechnung konnte nicht geladen werden.")));
    }
    const { title, pdf, positions } = invoice.sevdeskInvoice;
    const { invoiceCallItems } = invoice;
    const invoiceCallItemsInFlat = invoiceCallItems.filter((i) => !!i.secondsInFlat);
    const invoiceCallItemsOutsideFlat = invoiceCallItems.filter((i) => !!i.roundedSecondsOutsideFlat);
    return (React.createElement(FlexContainer, { style: { margin: "0 3em", paddingBottom: "4em" }, flexDirection: "column" },
        React.createElement(H2, null, title),
        React.createElement(FlexContainer, { gap: "4em", flexDirection: "column", alignItems: "flex-start" },
            React.createElement(PrintInvisibleFlexContainer, { gap: "1em", flexDirection: "column", alignItems: "flex-start" },
                React.createElement(H3, null, "Positionen"),
                React.createElement(PositionsGridContainer, null,
                    React.createElement(HeadingGridItem, null,
                        React.createElement("b", null, "Menge")),
                    React.createElement(HeadingGridItem, null,
                        React.createElement("b", null, "Name")),
                    React.createElement(HeadingGridItem, null,
                        React.createElement("b", null, "Preis pro Einheit (\u20AC)")),
                    React.createElement(HeadingGridItem, null,
                        React.createElement("b", null, "Preis (\u20AC)")),
                    positions.map((p) => (React.createElement(Fragment, { key: p.id },
                        React.createElement(GridItem, null, `${p.quantity} (${unitsTranslations[p.unit] || "pauschal"})`),
                        React.createElement(GridItem, null, p.name),
                        React.createElement(GridItem, null, p.pricePerUnit.toFixed(2)),
                        React.createElement(GridItem, null, p.price.toFixed(2)))))),
                pdf && (React.createElement(PrintInvisibleLink, { download: pdf.name, href: `data:application/pdf;base64,${pdf.content}` }, "Download PDF"))),
            React.createElement(FlexContainer, { gap: "1em", flexDirection: "column", alignItems: "flex-start" },
                React.createElement(H3, null, "Einzelverbindungen"),
                invoiceCallItems.length > 0 ? (React.createElement(FlexContainer, { gap: "2em", flexDirection: "column", alignItems: "flex-start" },
                    invoiceCallItemsInFlat.length > 0 && (React.createElement(NoPageBreakFlexContainer, { flexDirection: "column", gap: "1em", alignItems: "flex-start" },
                        React.createElement(H4, { color: "darkGray" }, "Monatliches Kontingent"),
                        React.createElement(InvoiceCallItemsGrid, { items: invoiceCallItemsInFlat }))),
                    invoiceCallItemsOutsideFlat.length > 0 && (React.createElement(NoPageBreakFlexContainer, { flexDirection: "column", gap: "1em", alignItems: "flex-start" },
                        React.createElement(H4, { color: "darkGray" }, "Abrechnung nach Minuten"),
                        React.createElement(InvoiceCallItemsGrid, { items: invoiceCallItemsOutsideFlat, outsideFlat: true }))))) : (React.createElement("span", null, "Keine Einzelverbindungen vorhanden")),
                React.createElement(PrintInvisibleLink, { onClick: printPage }, "Drucken")))));
}
