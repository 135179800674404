import * as yup from "yup";
const requiredString = yup.string().required("ist erforderlich");
const requiredEmail = requiredString.email("muss eine E-Mail sein");
const nullableString = yup.string().nullable().defined();
const optionalString = yup.string().optional();
export const userSchema = yup.object({
    firstName: requiredString,
    lastName: requiredString,
    title: nullableString,
    responsibility: nullableString,
    email: requiredEmail,
    emailConfirm: requiredEmail.equals([yup.ref("email")], "muss E-Mail entsprechen"),
});
export const phoneNumberSchema = yup.object({
    id: optionalString,
    number: requiredString.test("isValidPhoneNumber", "Ländervorwahl (z.B. +49) und nur Zahlen eingeben", (number) => number ? /^\+{1}(41|43|49)\d+$/.test(number) : true),
});
const addressSchema = yup.object({
    id: optionalString,
    addressLine1: requiredString,
    addressLine2: nullableString,
    postcode: requiredString,
    city: requiredString,
});
export const clientSchema = yup.object({
    name: requiredString,
    phoneNumbersAttributes: yup.array().of(phoneNumberSchema).required(),
});
export const customerSchema = yup
    .object({
    name: requiredString,
    addressesAttributes: yup.array().of(addressSchema).required(),
    usersAttributes: yup.array().of(userSchema).required(),
    clientsAttributes: yup.array().of(clientSchema).required(),
})
    .required();
export const customerUpdateSchema = yup
    .object({
    id: requiredString,
    addressesAttributes: yup.array().of(addressSchema).required(),
})
    .required();
