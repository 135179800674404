var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import styled from "styled-components";
import { style } from "config";
const { colors } = style;
import FlexItem from "components/shared/layout/FlexItem";
const HomeItemRoot = styled(FlexItem) `
  background-color: ${(p) => (p.backgroundColor ? p.backgroundColor : "inherit")};
  min-height: 300px;
`;
export function HomeItem(props) {
    const { isInverse, gray, children, split = false, desktop } = props, rest = __rest(props, ["isInverse", "gray", "children", "split", "desktop"]);
    let backgroundColor = colors.secondary;
    if (isInverse) {
        backgroundColor = colors.primary;
    }
    if (gray) {
        backgroundColor = colors.lightGray;
    }
    const desktopPadding = split ? "4em 12%" : "4em 36%";
    return (React.createElement(HomeItemRoot, Object.assign({ display: "flex", flexDirection: "column", alignItems: "center", column: 1 / 1, backgroundColor: backgroundColor, desktop: Object.assign({ textAlign: "center", padding: desktopPadding }, desktop) }, rest), children));
}
