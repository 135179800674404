import * as React from "react";
import Button from "components/shared/primitives/Button";
import { H2 } from "components/shared/primitives/Heading";
import { Paragraph } from "components/shared/primitives/Paragraph";
import { HomeItem } from "components/Home/HomeItem";
export default function UnscheduledShift(props) {
    const { createShift } = props;
    const handleClick = (event) => {
        event.preventDefault();
        createShift({ onSuccess: () => window.scrollTo(0, 0) });
    };
    return (React.createElement(HomeItem, { gray: true },
        React.createElement(H2, { color: "primary" }, "Flexible Schicht"),
        React.createElement(Paragraph, null, `Du kannst spontan eine Schicht übernehmen. Sie gilt von jetzt an bis du sie stoppst
          und maximal für ${process.env.FLEXIBLE_SHIFT_DURATION || "3"} Stunden.`),
        React.createElement(Button, { noFloat: true, onClick: handleClick }, "Flexible Schicht starten")));
}
