import * as React from "react";
import { Route, Routes } from "react-router-dom";
import Loading from "./Loading";
import ProtectedElement from "./ProtectedElement";
import LoginAwareElement from "./LoginAwareElement";
import { routes } from "../../config";
import { GlobalStyle } from "../../styles";
import Home from "../Home";
import SendPasswordLink from "../SendPasswordLink";
import PasswordReset from "../PasswordReset";
import UserActivation from "../UserActivation";
import Footer from "../Footer";
import Header from "../Header";
import NotFound from "../NotFound";
import OwnShifts from "../Shifts/Own";
import ShiftsPlan from "../Shifts/Plan";
import TranslatorStats from "../TranslatorStats";
import FlexContainer from "../shared/layout/FlexContainer";
import FlexItem from "../shared/layout/FlexItem";
import ManagePhoneNumbers from "components/ManagePhoneNumbers";
import CustomerStats from "components/CustomerStats";
import CustomerInvoices from "components/CustomerInvoices";
import CustomerInvoice from "components/CustomerInvoice";
import RegisterCustomer from "components/RegisterCustomer";
import ManageAddress from "components/ManageAddress";
import PaymentModel from "components/PaymentModel";
import TeachingMaterial from "components/TeachingMaterial";
import Events from "components/Events";
import TranslatorCreditNotes from "components/TranslatorCreditNotes";
import TranslatorCreditNote from "components/TranslatorCreditNote";
export default function App() {
    return (React.createElement(Loading, null,
        React.createElement(GlobalStyle, null),
        React.createElement(FlexContainer, { flexDirection: "column", flexWrap: "nowrap", justifyContent: "flex-start", alignItems: "stretch", fullHeight: true },
            React.createElement(FlexItem, { padding: "0", desktop: { padding: "0" } },
                React.createElement(Header, null)),
            React.createElement(FlexItem, { textAlign: "inherit", padding: "0", flexGrow: 2, flex: "1", desktop: { padding: "0" } },
                React.createElement(Routes, null,
                    React.createElement(Route, { path: routes.root(), element: React.createElement(ProtectedElement, { component: React.createElement(Home, null), redirectPath: routes.login() }) }),
                    React.createElement(Route, { path: routes.registerCustomer(), element: React.createElement(RegisterCustomer, null) }),
                    React.createElement(Route, { path: routes.forgotPassword(), element: React.createElement(SendPasswordLink, null) }),
                    React.createElement(Route, { path: `${routes.forgotPassword()}/:resetToken`, element: React.createElement(PasswordReset, null) }),
                    React.createElement(Route, { path: "/activate/:activationToken", element: React.createElement(UserActivation, null) }),
                    React.createElement(Route, { path: routes.login(), element: React.createElement(LoginAwareElement, null) }),
                    React.createElement(Route, { path: routes.planShifts(), element: React.createElement(ProtectedElement, { component: React.createElement(ShiftsPlan, null) }) }),
                    React.createElement(Route, { path: routes.manageAddress(), element: React.createElement(ProtectedElement, { component: React.createElement(ManageAddress, null) }) }),
                    React.createElement(Route, { path: routes.paymentModel(), element: React.createElement(ProtectedElement, { component: React.createElement(PaymentModel, null) }) }),
                    React.createElement(Route, { path: routes.managePhoneNumbers(), element: React.createElement(ProtectedElement, { component: React.createElement(ManagePhoneNumbers, null) }) }),
                    React.createElement(Route, { path: routes.ownShifts(), element: React.createElement(ProtectedElement, { component: React.createElement(OwnShifts, null) }) }),
                    React.createElement(Route, { path: routes.translatorStats(), element: React.createElement(ProtectedElement, { component: React.createElement(TranslatorStats, null) }) }),
                    React.createElement(Route, { path: routes.customerStats(), element: React.createElement(ProtectedElement, { component: React.createElement(CustomerStats, null) }) }),
                    React.createElement(Route, { path: routes.teachingMaterial(), element: React.createElement(ProtectedElement, { component: React.createElement(TeachingMaterial, null) }) }),
                    React.createElement(Route, { path: routes.events(), element: React.createElement(ProtectedElement, { component: React.createElement(Events, null) }) }),
                    React.createElement(Route, { path: routes.customerInvoices(), element: React.createElement(ProtectedElement, { component: React.createElement(CustomerInvoices, null) }) }),
                    React.createElement(Route, { path: routes.customerInvoice("/:invoiceId"), element: React.createElement(ProtectedElement, { component: React.createElement(CustomerInvoice, null) }) }),
                    React.createElement(Route, { path: routes.translatorCreditNotes(), element: React.createElement(ProtectedElement, { component: React.createElement(TranslatorCreditNotes, null) }) }),
                    React.createElement(Route, { path: routes.translatorCreditNote("/:creditNoteId"), element: React.createElement(ProtectedElement, { component: React.createElement(TranslatorCreditNote, null) }) }),
                    React.createElement(Route, { path: "*/*", element: React.createElement(NotFound, null) }))),
            React.createElement(FlexItem, { padding: "0", desktop: { padding: "0" } },
                React.createElement(Footer, null)))));
}
