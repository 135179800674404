import React, { Fragment } from "react";
import styled from "styled-components";
import { Link as LinkBlueprint } from "react-router-dom";
import { style as styleConfig, routes, externalLinks } from "config";
const { colors } = styleConfig;
import FlexContainer from "components/shared/layout/FlexContainer";
import { Base } from "components/shared/primitives/Heading";
import FlexItem from "components/shared/layout/FlexItem";
import LogoForProduct from "components/shared/primitives/LogoForProduct";
import Link from "components/shared/primitives/Link";
import { useSelector } from "react-redux";
import { selectUser } from "selectors/user";
const Root = styled.footer `
  width: 100%;
  color: ${colors.secondary};
  background-color: ${colors.darkGray};

  @media print {
    display: none;
  }
`;
const CopyrightText = styled.div `
  font-size: 0.75em;
  margin-top: 2em;
  color: ${colors.lightGray};
  text-align: center;
`;
const RouterLink = styled(LinkBlueprint) `
  color: ${colors.white};
`;
export const ListHeader = styled(Base) `
  color: ${styleConfig.colors.fontGrayDark};
  font-size: 1.2em;
`;
const LinkWrapper = styled.span `
  display: block;
  margin-bottom: 0.5em;

  a {
    color: ${colors.white};
  }

  @media (${styleConfig.media.desktop}) {
    display: inline-block;
    margin-bottom: 0;
  }
`;
const SocialMediaItem = styled.span `
  &::before {
    font-size: 1.25em;
    display: inline-block;
    font-family: "Roboto", sans-serif;
    background-color: ${colors.fontGray};
    color: ${colors.white};
    width: 1.5em;
    height: 1.5em;
    line-height: 1.56em;
    text-align: center;
    border-radius: 100%;
    font-weight: bold;
  }
`;
const FacebookItem = styled(SocialMediaItem) `
  &::before {
    content: "f";
  }

  &:hover::before {
    background-color: ${colors.facebookBlue};
  }
`;
const LinkedInItem = styled(SocialMediaItem) `
  &::before {
    content: "in";
  }

  &:hover::before {
    background-color: ${colors.linkedInBlue};
  }
`;
function FooterContent() {
    const user = useSelector(selectUser);
    if (user && user.translatorId) {
        return (React.createElement(Fragment, null,
            React.createElement(ListHeader, null,
                React.createElement(Link, { color: styleConfig.colors.fontGrayDark, bold: false, href: externalLinks.tos }, "Allgemeine Gesch\u00E4ftsbedingungen")),
            React.createElement(ListHeader, null,
                React.createElement(Link, { color: styleConfig.colors.fontGrayDark, bold: false, href: externalLinks.privacyPolicy }, "Datenschutzerkl\u00E4rung")),
            React.createElement(ListHeader, null,
                React.createElement(Link, { color: styleConfig.colors.fontGrayDark, bold: false, href: externalLinks.confidentiality }, "Schweigepflicht")),
            React.createElement(ListHeader, null,
                React.createElement(Link, { color: styleConfig.colors.fontGrayDark, bold: false, href: externalLinks.taxPaymentConsent }, "Hinweise zur Versteuerung"))));
    }
    else if (user && user.customerId) {
        return (React.createElement(Fragment, null,
            React.createElement(ListHeader, null,
                React.createElement(Link, { color: styleConfig.colors.fontGrayDark, bold: false, href: externalLinks.customerTos }, "AGB")),
            React.createElement(ListHeader, null,
                React.createElement(Link, { color: styleConfig.colors.fontGrayDark, bold: false, href: externalLinks.dpa }, "AVV")),
            React.createElement(ListHeader, null,
                React.createElement(Link, { color: styleConfig.colors.fontGrayDark, bold: false, href: externalLinks.privacyPolicy }, "Datenschutz"))));
    }
    else {
        return (React.createElement(Fragment, null,
            React.createElement(ListHeader, null,
                React.createElement(Link, { color: styleConfig.colors.fontGrayDark, bold: false, href: externalLinks.imprint }, "Impressum")),
            React.createElement(ListHeader, null,
                React.createElement(Link, { color: styleConfig.colors.fontGrayDark, bold: false, href: externalLinks.privacyPolicy }, "Datenschutzerkl\u00E4rung")),
            React.createElement(ListHeader, null,
                React.createElement(Link, { color: styleConfig.colors.fontGrayDark, bold: false, href: externalLinks.advisoryBoard }, "Beirat"))));
    }
}
export default function Footer() {
    return (React.createElement(Root, null,
        React.createElement(FlexContainer, { flexWrap: "wrap", justifyContent: "stretch", alignItems: "flex-start" },
            React.createElement(FlexItem, { column: 1 / 1, padding: "2em", flexGrow: 0, flexShrink: 2, desktop: { column: "auto", padding: "2em 5em" } },
                React.createElement(RouterLink, { to: routes.root() },
                    React.createElement(LogoForProduct, { inFooter: true })),
                React.createElement(CopyrightText, null,
                    "\u00A9\uFE0F ",
                    new Date().getFullYear(),
                    " Triaphon")),
            React.createElement(FlexItem, { column: 1 / 1, padding: "1em", flexGrow: 0, flexShrink: 2, desktop: { column: "auto", padding: "2em 0" } },
                React.createElement(FlexContainer, { gap: "1rem" },
                    React.createElement(LinkWrapper, null,
                        React.createElement("a", { href: externalLinks.linkedIn, target: "_blank", rel: "noreferrer noopener" },
                            React.createElement(LinkedInItem, null))),
                    React.createElement(LinkWrapper, null,
                        React.createElement("a", { href: externalLinks.facebook, target: "_blank", rel: "noreferrer noopener" },
                            React.createElement(FacebookItem, null))))),
            React.createElement(FlexItem, { column: 1 / 1, flexGrow: 2, flexShrink: 1, padding: "1em", desktop: { column: "auto", textAlign: "right", padding: "2em 5em 2em 0" } },
                React.createElement(FlexContainer, { flexDirection: "row", flexWrap: "wrap", alignItems: "flex-start", justifyContent: "flex-end" },
                    React.createElement(FlexContainer, { flexDirection: "column" },
                        React.createElement(FooterContent, null)))))));
}
