import * as React from "react";
import styled from "styled-components";
import moment from "moment";
import { formatDayLong, formatWeekspan } from "components/helpers/dateFormatting";
import { mapShiftsToWeeks } from "components/helpers/shifts";
import FlexContainer from "components/shared/layout/FlexContainer";
import Icon from "components/shared/primitives/Icon";
import { H2 } from "components/shared/primitives/Heading";
import { RouterLink } from "components/shared/primitives/Link";
import { Paragraph } from "components/shared/primitives/Paragraph";
import { ButtonAsLink } from "components/shared/primitives/Button";
import { DateHeader, ShiftItem, DayItem } from "components/shared/Shift";
import { style as styleConfig, routes } from "config";
import { HomeItem } from "components/Home/HomeItem";
const MAX_PREVIEW_SHIFTS = 3;
const WeekItem = styled(FlexContainer) `
  flex-direction: column;
  align-items: flex-start;
`;
const ShiftsByWeekRoot = styled.div `
  display: flex;
  flex-direction: column;
  min-height: 10em;
  margin-top: 1em;
  justify-content: space-between;
`;
function ShiftsByWeek(props) {
    const { shifts } = props;
    return (React.createElement(ShiftsByWeekRoot, null,
        Object.keys(shifts).map((weekKey) => (React.createElement(WeekItem, { key: weekKey },
            React.createElement(DateHeader, { centered: true, color: "primary" }, formatWeekspan(moment(weekKey, "YYYYw"))),
            Object.keys(shifts[weekKey]).map((dayKey) => (React.createElement(DayItem, { key: dayKey },
                React.createElement(DateHeader, { color: "fontGray" }, formatDayLong(moment(dayKey, "YYYYDDD"))),
                shifts[weekKey][dayKey].map((shift) => (React.createElement(ShiftItem, { key: shift.id, shift: shift }))))))))),
        React.createElement("div", null,
            React.createElement(AllShifts, null,
                React.createElement(Icon, { color: "primary", icon: "calendarThick" }),
                React.createElement(RouterLink, { to: routes.ownShifts() }, "Alle deine Schichten ansehen")),
            React.createElement(ChooseShiftButton, { inverse: true, border: styleConfig.colors.primary, to: routes.planShifts() }, "Jetzt Schicht w\u00E4hlen"))));
}
const AllShifts = styled.div `
  display: flex;
  align-items: center;
  margin-top: -0.5em;
`;
const ChooseShiftButton = styled(ButtonAsLink) `
  width: 80%;
  align-self: center;
  margin-top: 4rem;
`;
export default function ScheduledShiftsPreview(props) {
    const { scheduledShifts } = props;
    let shiftsByWeek;
    if (scheduledShifts.length > 0) {
        shiftsByWeek = mapShiftsToWeeks(scheduledShifts.slice(0, MAX_PREVIEW_SHIFTS));
    }
    return (React.createElement(HomeItem, { gray: false },
        React.createElement(H2, { withSubtitle: true, color: "primary" }, "Deine n\u00E4chsten Schichten"),
        shiftsByWeek ? (React.createElement(ShiftsByWeek, { shifts: shiftsByWeek })) : (React.createElement(React.Fragment, null,
            React.createElement(Paragraph, null, "Du hast noch keine Schicht ausgew\u00E4hlt"),
            React.createElement(ButtonAsLink, { inverse: true, border: styleConfig.colors.primary, to: routes.planShifts() }, "Schicht w\u00E4hlen")))));
}
