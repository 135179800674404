import styled from "styled-components";
import { style as styleConfig } from "config";
const DesktopOnly = styled.div `
  display: none;
  @media (${styleConfig.media.desktop}) {
    display: inherit;
  }

  @media print {
    display: inherit;
    margin-top: auto;
  }
`;
export default DesktopOnly;
