import * as React from "react";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import moment from "moment";
import { formatMonth } from "../helpers/dateFormatting";
import { useAppDispatch } from "reduxStore";
import { selectTranslatorStats } from "selectors/translator";
import { getTranslatorStatsAction } from "slices/translator";
import FlexContainer from "../shared/layout/FlexContainer";
import FlexItem from "../shared/layout/FlexItem";
import DateControls from "../shared/DateControls";
import TranslatorStatsList from "./presentational/TranslatorStatsList";
import { style as styleConfig } from "../../config";
const { colors } = styleConfig;
const Root = styled(FlexContainer) `
  flex-direction: column;
`;
const MainContent = styled(FlexItem) `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: ${colors.lightGray};
`;
export default function TranslatorStats() {
    const startOfMonth = moment().startOf("month").clone();
    const [currentDay, setCurrentDay] = useState(startOfMonth);
    const translatorStatsState = useSelector(selectTranslatorStats);
    const dispatch = useAppDispatch();
    const getUserStats = () => dispatch(getTranslatorStatsAction());
    useEffect(() => {
        getUserStats();
    }, []);
    return (React.createElement(Root, null,
        React.createElement(MainContent, { column: 1 / 1, padding: "3em 0" },
            React.createElement(DateControls, { currentDay: currentDay, delta: 1, deltaUnit: "months", direction: "past", displayFormatter: formatMonth, jumpToCurrentText: "Zum aktuellen Monat", setCurrentDay: setCurrentDay, start: startOfMonth }),
            React.createElement(TranslatorStatsList, { statsState: translatorStatsState, currentDay: currentDay }))));
}
