import * as React from "react";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { formatTimeRemaining } from "components/helpers/dateFormatting";
const NoWrap = styled.span `
  white-space: nowrap;
`;
export default function Countdown(props) {
    const { endsAt } = props;
    const tick = () => setTimeRemaining(formatTimeRemaining(endsAt));
    const [timeRemaining, setTimeRemaining] = useState("");
    const [timer, setTimer] = useState(null);
    useEffect(() => {
        tick();
        setTimer(window.setInterval(tick, 1000));
    }, []);
    useEffect(() => () => {
        timer && clearInterval(timer);
        setTimer(null);
    }, []);
    return React.createElement(NoWrap, null, timeRemaining);
}
