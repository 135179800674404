import moment from "moment";
import { sortBy, uniqBy } from "lodash-es";
export const selectCurrentShift = (state) => {
    return state.shifts.models.find((shift) => {
        if (!state.user.model)
            return false;
        return (shift.translatorId === state.user.model.translatorId &&
            moment(shift.startsAt).isSameOrBefore(moment()) &&
            moment(shift.endsAt).isSameOrAfter(moment()));
    });
};
export const selectPlannableShifts = (state) => {
    const scheduledShifts = state.shifts.models.filter((shift) => shift.scheduled);
    const assignedShifts = scheduledShifts.filter((shift) => shift.translatorId);
    const scheduledWithoutOwnOrAssigned = scheduledShifts.filter((shift) => !shift.translatorId &&
        !assignedShifts.find((s) => s.startsAt === shift.startsAt && s.translatorId === (state.user.model && state.user.model.translatorId)));
    const uniqueUnassignedShifts = uniqBy(scheduledWithoutOwnOrAssigned, (shift) => shift.startsAt);
    return uniqueUnassignedShifts.map((shift) => {
        const slotsTaken = assignedShifts.filter((s) => s.startsAt == shift.startsAt).length;
        return Object.assign(Object.assign({}, shift), { slotsTaken });
    });
};
export const selectScheduledShifts = (state) => {
    if (!state.shifts)
        return [];
    const ownScheduled = (shift) => shift.scheduled && shift.translatorId === (state.user.model && state.user.model.translatorId);
    const shifts = state.shifts.models.filter((shift) => ownScheduled(shift));
    return sortBy(shifts, ["startsAt"]);
};
export const selectAreShiftsLoading = (state) => {
    if (!state.shifts)
        return true;
    return state.shifts.isLoading;
};
