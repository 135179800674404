export function isOAuth2Request(queryString) {
  const queryParams = new URLSearchParams(queryString);
  return (
    queryParams.has("client_id") &&
    queryParams.has("response_type") &&
    queryParams.has("redirect_uri") &&
    queryParams.has("scope")
  );
}

export function redirectToOAuthClient(urlParams) {
  const redirectUrl = new URL("/oauth/authorize", window.location.origin);
  redirectUrl.search = urlParams;
  window.location = redirectUrl.toString();
}
