import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "reduxStore";
import moment from "moment";
import { routes } from "config";
import { selectCustomerInvoices } from "selectors/customer";
import { getCustomerInvoicesAction } from "slices/customer";
import { formatYear } from "./helpers/dateFormatting";
import DateControls from "./shared/DateControls";
import FlexContainer from "./shared/layout/FlexContainer";
import { H2 } from "./shared/primitives/Heading";
import { RouterLink } from "./shared/primitives/Link";
import Spinner from "./shared/Spinner";
import Paragraph from "./shared/primitives/Paragraph";
export default function CustomerInvoices() {
    const startOfYear = moment().startOf("year").clone();
    const dispatch = useAppDispatch();
    const [currentDay, setCurrentDay] = useState(startOfYear);
    const currentYear = currentDay.format("YYYY");
    const invoicesState = useSelector(selectCustomerInvoices);
    const { data: invoices, loading } = invoicesState;
    useEffect(() => {
        const params = { year: currentYear };
        dispatch(getCustomerInvoicesAction(params));
    }, [currentYear]);
    return (React.createElement(FlexContainer, { gap: "2em", flexDirection: "column" },
        React.createElement(H2, null, "Rechnungen"),
        React.createElement(DateControls, { delta: 1, deltaUnit: "years", currentDay: currentDay, setCurrentDay: setCurrentDay, jumpToCurrentText: "Aktuelles Jahr", displayFormatter: formatYear, direction: "past", start: startOfYear }),
        React.createElement(FlexContainer, { gap: "1em", flexDirection: "column", fullWidth: true },
            loading && React.createElement(Spinner, { color: "primary" }),
            !loading && !(invoices === null || invoices === void 0 ? void 0 : invoices.length) && React.createElement(Paragraph, null, "Keine Rechnungen vorhanden."),
            !loading &&
                !!(invoices === null || invoices === void 0 ? void 0 : invoices.length) &&
                invoices.map((i) => (React.createElement(RouterLink, { key: i.id, to: routes.customerInvoice(`/${i.id}`) }, i.sevdeskInvoice.title))))));
}
