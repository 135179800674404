import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Cell, Legend, Line, LineChart, Pie, PieChart, Tooltip, XAxis, YAxis } from "recharts";
import { useAppDispatch } from "reduxStore";
import { selectCustomer, selectCustomerStats } from "selectors/customer";
import { getCustomerStatsAction } from "slices/customer";
import { style as styleConfig } from "config";
import FlexContainer from "./shared/layout/FlexContainer";
import styled from "styled-components";
import DateControls, { DefaultDateDisplay } from "./shared/DateControls";
import moment from "moment";
import { formatMonth, formatYear } from "./helpers/dateFormatting";
import { H3, PrintOnlyHeading } from "./shared/primitives/Heading";
import Link from "./shared/primitives/Link";
import DesktopOnly from "./shared/primitives/DesktopOnly";
function gatherLineChartData(stats, granularity) {
    return stats.durations.dataPoints.map((dataPoint, index) => {
        const date = Date.parse(dataPoint.label);
        let formatOpts = { day: "2-digit", month: "short" };
        if (granularity === "year") {
            formatOpts = { month: "long" };
        }
        const label = new Intl.DateTimeFormat("de", formatOpts).format(date);
        const duration = dataPoint.value;
        const calls = stats.calls.dataPoints[index].value;
        return { label, duration, calls };
    });
}
function translateLabels(data, translations) {
    return data.map((dataPoint) => {
        Object.keys(translations).map((oldKey) => {
            const newKey = translations[oldKey];
            delete Object.assign(dataPoint, { [newKey]: dataPoint[oldKey] })[oldKey];
        });
        return dataPoint;
    });
}
function InfoBox(props) {
    const { sum, average, label, granularity } = props;
    const unit = granularity === "year" ? "Monat" : "Tag";
    const Root = styled.div `
    display: flex;
    flex-direction: column;
    width: 40vw;
    padding: 1rem 1rem 4rem 1rem;
    border: 1px solid black;

    @media (${styleConfig.media.desktop}) {
      font-size: 1.2rem;
      width: 200px;
      flex-shrink: 1;
    }

    @media print {
      display: flex;
      width: 40mm;
    }
  `;
    const SumBox = styled.div `
    margin-bottom: 1rem;

    @media (${styleConfig.media.desktop}) {
      font-size: 1.5rem;
    }
  `;
    return (React.createElement(Root, null,
        React.createElement(SumBox, null,
            React.createElement("div", null, sum),
            React.createElement("div", null, label)),
        React.createElement("div", null,
            React.createElement("small", null, average)),
        React.createElement("div", null,
            React.createElement("small", null,
                "\u00D8 / ",
                unit))));
}
function CustomerStatsCharts(props) {
    const { stats, granularity } = props;
    const lineChartLabels = { calls: "Anrufe", duration: "Dauer" };
    const lineChartData = gatherLineChartData(stats, granularity);
    const finalLineChartData = translateLabels(lineChartData, lineChartLabels);
    const pieChartHeight = 300;
    const pieChartColors = {
        arabic: "#65564a",
        vietnamese: "#dd2f15",
        russian: "#9c7495",
        dari: "#ae9173",
        turkish: "#e41825",
        romanian: "#99553a",
        polish: "#ee859c",
        bulgarian: "#9c927d",
    };
    const pieChartColor = (lang) => pieChartColors[lang] || "#9c927d";
    const Root = styled(FlexContainer) `
    gap: 2rem;
    justify-content: center;
    flex-direction: column;
    margin: 3rem auto;

    @media (${styleConfig.media.desktop}) {
      flex-direction: row;
      padding: 0 5vw;
      width: 1000px;
    }
  `;
    const FirstLine = styled(FlexContainer) `
    flex-direction: column;
    gap: 2rem;
    width: 100%;

    @media (${styleConfig.media.desktop}) {
      gap: 1rem;
      justify-content: space-between;
      width: 25vw;
    }

    @media print {
      flex-direction: row;
    }
  `;
    const InfoBoxes = styled(FlexContainer) `
    gap: 1rem;
    height: ${pieChartHeight}px;
    width: 100%;
  `;
    return (React.createElement(Root, null,
        React.createElement(FirstLine, null,
            React.createElement(InfoBoxes, null,
                React.createElement(InfoBox, { sum: stats.calls.sum, average: stats.calls.average, label: "Anrufe", granularity: granularity }),
                React.createElement(InfoBox, { sum: (stats.durations.sum / 60).toFixed(1), average: (stats.durations.average / 60).toFixed(1), label: "Min.", granularity: granularity })),
            React.createElement(DesktopOnly, null,
                React.createElement(PieChart, { width: 600, height: pieChartHeight },
                    React.createElement(Pie, { data: stats.languages, outerRadius: 80, dataKey: "value", nameKey: "label", labelLine: false, label: (p) => p.name, isAnimationActive: false }, stats.languages.map((entry, index) => (React.createElement(Cell, { key: `cell-${index}`, fill: pieChartColor(entry.key) })))),
                    React.createElement(Tooltip, { separator: ": ", formatter: (value, name) => [`${value.toLocaleString()} %`, name] })))),
        React.createElement(DesktopOnly, null,
            React.createElement(LineChart, { width: 900, height: 500, data: finalLineChartData },
                React.createElement(XAxis, { dataKey: "label" }),
                React.createElement(YAxis, { label: { value: "Anrufe", angle: -90, position: "left", offset: -2 }, yAxisId: "calls" }),
                React.createElement(YAxis, { label: { value: "Dauer (s)", angle: -270, position: "right", offset: -2 }, yAxisId: "duration", orientation: "right" }),
                React.createElement(Line, { dataKey: "Anrufe", yAxisId: "calls", stroke: styleConfig.colors.primary, isAnimationActive: false }),
                React.createElement(Line, { dataKey: "Dauer", yAxisId: "duration", stroke: styleConfig.colors.fontGray, isAnimationActive: false }),
                React.createElement(Legend, null),
                React.createElement(Tooltip, null)))));
}
function CustomerStatsDateControl(props) {
    const { today, currentDay, setCurrentDay, clientId, setClientId, granularity, setGranularity } = props;
    const customer = useSelector(selectCustomer);
    const dateControlsStart = today.clone().startOf(granularity);
    const dateDisplay = (underlined, isCurrentDate, handleNavigation) => {
        const formatter = granularity === "year" ? formatYear : formatMonth;
        const yearLabel = "Jahr";
        const monthLabel = "Monat";
        const jumpToCurrentDateText = `Zum aktuellen ${granularity === "year" ? yearLabel : monthLabel}`;
        const handleSelectGranularity = (event) => {
            if (event.target.value === "month" || event.target.value === "year") {
                setGranularity(event.target.value);
                setCurrentDay(today.clone().startOf(event.target.value));
            }
        };
        const handleSelectClientId = (event) => {
            const { value } = event.target;
            setClientId(value === "" ? undefined : value);
        };
        return (React.createElement(DefaultDateDisplay, { underlined: underlined },
            React.createElement(FlexContainer, { gap: "1rem", flexWrap: "wrap" },
                React.createElement(FlexContainer, { flexDirection: "column" },
                    React.createElement(H3, { solo: true }, formatter(currentDay)),
                    !isCurrentDate && React.createElement(Link, { onClick: handleNavigation(dateControlsStart) }, jumpToCurrentDateText)),
                React.createElement("select", { onChange: handleSelectGranularity, value: granularity },
                    React.createElement("option", { value: "month" }, "Monatsanzeige "),
                    React.createElement("option", { value: "year" }, "Jahresanzeige")),
                customer && customer.clients.length > 1 && (React.createElement("select", { onChange: handleSelectClientId, value: clientId },
                    React.createElement("option", { value: "" }, "Alle Klienten"),
                    customer.clients.map((client) => (React.createElement("option", { key: client.id, value: client.id }, client.name))))))));
    };
    return (React.createElement(DateControls, { start: dateControlsStart, currentDay: currentDay, setCurrentDay: setCurrentDay, dateDisplay: dateDisplay, deltaUnit: granularity, delta: 1, direction: "past" }));
}
export default function CustomerStats() {
    const stats = useSelector(selectCustomerStats);
    const dispatch = useAppDispatch();
    const [granularity, setGranularity] = useState("month");
    const today = moment();
    const [currentDay, setCurrentDay] = useState(today.clone().startOf(granularity));
    const [clientId, setClientId] = useState();
    const formattedCurrentDay = currentDay.format("YYYY-MM-DD");
    useEffect(() => {
        const params = { date: formattedCurrentDay, granularity, client_id: clientId };
        dispatch(getCustomerStatsAction(params));
    }, [granularity, formattedCurrentDay, clientId]);
    if (!stats)
        return null;
    const printHeadingDate = granularity === "year" ? currentDay.format("YYYY") : currentDay.format("MMMM YYYY");
    return (React.createElement(FlexContainer, { flexDirection: "column" },
        React.createElement(CustomerStatsDateControl, { today: today, currentDay: currentDay, setCurrentDay: setCurrentDay, granularity: granularity, setGranularity: setGranularity, clientId: clientId, setClientId: setClientId }),
        React.createElement(PrintOnlyHeading, null,
            "Triaphon Statistik ",
            printHeadingDate),
        React.createElement(CustomerStatsCharts, { stats: stats, granularity: granularity })));
}
