import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";
import validator from "validator";
import { sendResetPasswordLinkAction } from "slices/user";
import { style as styleConfig } from "../config";
const { colors } = styleConfig;
import FlexContainer from "./shared/layout/FlexContainer";
import FlexItem from "./shared/layout/FlexItem";
import Paragraph from "./shared/primitives/Paragraph";
import InputField from "./shared/primitives/InputField";
import Button from "./shared/primitives/Button";
import Spinner from "./shared/Spinner";
import Title from "./shared/layout/Title";
import { useAppDispatch } from "reduxStore";
import { selectUserSession } from "selectors/userSession";
import { useDisplayCustomerPrompts } from "customHooks";
const Root = styled(FlexContainer) `
  flex-direction: column;
`;
const MainContainer = styled(FlexItem) `
  background-color: ${colors.lightGray};
`;
const InputWrapper = styled.div `
  width: 100%;
  margin-bottom: 1em;
`;
const SendPasswordLinkButton = styled(Button) `
  margin-top: 1em;
  width: 80%;
`;
const SendPasswordLinkForm = styled.form `
  display: flex;
  flex-direction: column;
  align-items: center;
  ${(p) => p.hasError &&
    css `
      input {
        border-color: ${colors.primary};
      }
    `};
`;
export default function SendPasswordLink() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [value, setValue] = useState("");
    const userSessionState = useSelector(selectUserSession);
    const { isCustomer } = useDisplayCustomerPrompts();
    const handleChange = (event) => {
        const { target } = event;
        const { value } = target;
        setValue(value);
        let emailError = "";
        if (value && !validator.isEmail(value)) {
            emailError = "Muss eine gültige E-Mail sein";
        }
        setError(emailError);
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        setError(null);
        setIsLoading(true);
        dispatch(sendResetPasswordLinkAction({ email: value, isCustomer }))
            .then(() => navigate("/login"))
            .catch(() => setIsLoading(false));
    };
    const finalIsLoading = userSessionState.isLoading || isLoading;
    let sendPasswordLinkPrompt = "Gib deine E-Mail-Adresse ein und wir senden Dir einen Link mit weiteren Anweisungen zum Zurücksetzen deines Passworts.";
    let inputFieldLabel = "Deine E-Mail";
    if (isCustomer) {
        sendPasswordLinkPrompt =
            "Geben Sie ihre E-Mail-Adresse ein und wir senden Ihnen einen Link mit weiteren Anweisungen zum Zurücksetzen des Passworts.";
        inputFieldLabel = "Ihre E-Mail";
    }
    return (React.createElement(Root, null,
        React.createElement(Title, null, "Zur\u00FCcksetzen"),
        React.createElement(MainContainer, { column: 1 / 1 },
            React.createElement(Paragraph, null, sendPasswordLinkPrompt),
            React.createElement(SendPasswordLinkForm, { hasError: !!error, onSubmit: handleSubmit },
                React.createElement(InputWrapper, null,
                    React.createElement(InputField, { label: inputFieldLabel, name: "email", value: value, error: error, onChange: handleChange })),
                React.createElement(SendPasswordLinkButton, { type: "submit", disabled: finalIsLoading || !value || !!error }, finalIsLoading ? React.createElement(Spinner, null) : "Absenden")))));
}
