import * as React from "react";
import styled from "styled-components";
import moment from "moment";
import InfoBox from "../../shared/layout/InfoBox";
import Spinner from "../../shared/Spinner";
function humanize(time) {
    const result = [];
    const data = [
        { value: Math.floor(time / 3600), label: "Stunde" },
        { value: Math.floor((time % 3600) / 60), label: "Minute" },
        { value: Math.floor((time % 3600) % 60), label: "Sekunde" },
    ];
    for (const d of data) {
        if (d.value === 0)
            continue;
        const finalLabel = d.value === 1 ? ` ${d.label}` : ` ${d.label}n`;
        result.push(`${d.value} ${finalLabel}`);
    }
    return result.join(", ") || "0 Sekunden";
}
const Root = styled.div `
  padding-top: 25px;
`;
export default function TranslatorStatsList(props) {
    const { statsState, currentDay } = props;
    const noStatsText = "Für diesen Monat sind keine Statistiken vorhanden";
    if (statsState.isLoading)
        return React.createElement(Spinner, null);
    if (statsState.models.length === 0) {
        return React.createElement(Root, null, noStatsText);
    }
    const currentMonthStats = statsState.models.find((stats) => {
        const currentStartOfMonth = currentDay.startOf("month");
        const statsMonth = moment(stats.month);
        return (currentStartOfMonth.dayOfYear() === statsMonth.dayOfYear() && currentStartOfMonth.year() === statsMonth.year());
    });
    if (currentMonthStats) {
        return (React.createElement(Root, null,
            React.createElement(InfoBox, { icon: "plainPhone", heading: `${currentMonthStats.totalCalls} Anrufe` }, "Anrufe in diesem Monat angenommen."),
            React.createElement(InfoBox, { icon: "clock", heading: humanize(currentMonthStats.totalCallsDurationInSeconds) }, `Zeit in diesem Monat telefoniert. ${humanize(currentMonthStats.dayDuration)} tagsüber, ${humanize(currentMonthStats.nightDuration)} nachts.`),
            React.createElement(InfoBox, { icon: "hourglass", heading: humanize(currentMonthStats.standbyTime * 60) }, "Zeit in diesem Monat f\u00FCr Anrufe bereit gehalten.")));
    }
    else {
        return React.createElement(Root, null, noStatsText);
    }
}
