var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import axios from "axios";
import { withNotifySentry } from "./helpers";
export function getUser() {
    return __awaiter(this, void 0, void 0, function* () {
        return yield withNotifySentry(() => axios.get("/api/user"));
    });
}
export function activateUser(data) {
    return __awaiter(this, void 0, void 0, function* () {
        const { activationToken } = data, params = __rest(data, ["activationToken"]);
        return yield withNotifySentry(() => axios.post(`/api/user/activate/${activationToken}/`, params));
    });
}
export function updateUser(params) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield withNotifySentry(() => axios.put("/api/user", params));
    });
}
export function sendResetPasswordLink(email) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield axios.post("/api/password_resets", { email });
    });
}
export function resetPassword(data) {
    return __awaiter(this, void 0, void 0, function* () {
        const { resetToken } = data, params = __rest(data, ["resetToken"]);
        return withNotifySentry(() => axios.put(`/api/password_resets/${resetToken}/`, params));
    });
}
