var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { initialLoadAction, resetAppStateAction } from "actions/application";
import { createUserSession, destroyUserSession } from "api/userSession";
const initialState = { isLoading: false, error: null, info: null };
export const createUserSessionAction = createAsyncThunk("userSession/create", (params, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield createUserSession(params);
    thunkAPI.dispatch(initialLoadAction());
    return response;
}));
export const destroyUserSessionAction = createAsyncThunk("userSession/destroy", (_params, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield destroyUserSession();
    thunkAPI.dispatch(resetAppStateAction());
    return response;
}));
const userSessionSlice = createSlice({
    name: "userSession",
    initialState,
    reducers: {
        setLoginInfoAction(state, action) {
            state.info = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(createUserSessionAction.pending, (state) => {
            state.isLoading = true;
            state.info = null;
        })
            .addCase(createUserSessionAction.fulfilled, (state) => {
            state.isLoading = false;
            state.error = null;
        })
            .addCase(createUserSessionAction.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.error.message || action.error.name || null;
        })
            .addCase(resetAppStateAction, () => initialState);
    },
});
export const { setLoginInfoAction } = userSessionSlice.actions;
export default userSessionSlice;
