var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from "react";
import styled, { css } from "styled-components";
import Icon, { IconForDaytime } from "../shared/primitives/Icon";
import CheckboxPrimitive from "../shared/primitives/Checkbox";
import { formatTimespan } from "../helpers/dateFormatting";
import { style as styleConfig } from "../../config";
const { colors, media } = styleConfig;
export const DayItem = styled.div `
  display: flex;
  color: ${colors.fontGray};
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 1em;
  border-bottom: 1px solid ${colors.gray};

  &:last-child {
    border-bottom: none;
  }
`;
export const DateHeader = styled.span `
  margin-bottom: 1em;
  color: ${(p) => (p.color ? colors[p.color] : "inherit")};
  ${(p) => p.centered &&
    css `
      width: 100%;
      display: block;
      text-align: center;
      font-size: 1.25rem;
    `};
`;
const ShiftItemRoot = styled.div `
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 1em;
  padding-left: 1em;
`;
const Label = styled.label `
  display: flex;
  align-items: center;
  width: 14em;

  @media (${media.desktop}) {
    width: 25vw;
  }
`;
const Checkbox = styled(CheckboxPrimitive) `
  margin-left: auto;
`;
const ActiveIndicator = styled.span `
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 4em;
`;
function ShiftItemWithCheckbox(props) {
    const { shift, onChange, checked, active } = props, rest = __rest(props, ["shift", "onChange", "checked", "active"]);
    const { startsAt, endsAt, slotsTaken } = shift;
    let iconName = "shiftEmpty";
    let iconTitle = "Noch keine Schichten belegt.";
    if (slotsTaken === 1) {
        iconName = "shiftHalf";
        iconTitle = "Bereits eine Schicht belegt.";
    }
    return (React.createElement(ShiftItemRoot, Object.assign({}, rest),
        React.createElement(Label, { htmlFor: startsAt },
            React.createElement(IconForDaytime, { daytime: startsAt }),
            typeof slotsTaken === "number" && React.createElement(Icon, { icon: iconName, title: iconTitle }),
            formatTimespan(startsAt, endsAt)),
        active ? (React.createElement(ActiveIndicator, null,
            React.createElement(Icon, { color: "black", icon: "comment", margin: "0" }),
            " aktiv")) : (React.createElement(Checkbox, { id: startsAt, checked: !!checked, value: shift.id, onChange: onChange }))));
}
function SimpleShiftItem(props) {
    const { shift } = props, rest = __rest(props, ["shift"]);
    const { startsAt, endsAt } = shift;
    return (React.createElement(ShiftItemRoot, Object.assign({}, rest),
        React.createElement(IconForDaytime, { daytime: startsAt }),
        React.createElement("span", null, formatTimespan(startsAt, endsAt))));
}
export function ShiftItem(props) {
    const { onChange } = props, rest = __rest(props, ["onChange"]);
    if (onChange !== undefined) {
        return React.createElement(ShiftItemWithCheckbox, Object.assign({ onChange: onChange }, rest));
    }
    else {
        return React.createElement(SimpleShiftItem, Object.assign({}, rest));
    }
}
