var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getCustomer, updateCustomerClient, getCustomerStats, createCustomer, updateCustomer, getCustomerInvoices, getCustomerInvoice, } from "api/customer";
import { resetAppStateAction } from "actions/application";
const initialState = {
    model: null,
    stats: null,
    invoices: { loading: false, data: null },
    invoice: { loading: false, data: null },
};
export const createCustomerAction = createAsyncThunk("customer/create", (params) => __awaiter(void 0, void 0, void 0, function* () { return yield createCustomer(params); }));
export const updateCustomerAction = createAsyncThunk("customer/update", (params) => __awaiter(void 0, void 0, void 0, function* () { return yield updateCustomer(params); }));
export const getCustomerAction = createAsyncThunk("customer/get", () => __awaiter(void 0, void 0, void 0, function* () { return yield getCustomer(); }));
export const getCustomerStatsAction = createAsyncThunk("customer/getStats", (params) => __awaiter(void 0, void 0, void 0, function* () { return yield getCustomerStats(params); }));
export const getCustomerInvoicesAction = createAsyncThunk("customer/getInvoices", (params) => __awaiter(void 0, void 0, void 0, function* () { return yield getCustomerInvoices(params); }));
export const getCustomerInvoiceAction = createAsyncThunk("customer/getInvoice", (params) => __awaiter(void 0, void 0, void 0, function* () { return yield getCustomerInvoice(params); }));
export const updateCustomerClientAction = createAsyncThunk("customer/updateClient", (params) => __awaiter(void 0, void 0, void 0, function* () { return yield updateCustomerClient(params); }));
const setCustomer = (state, action) => {
    state.model = action.payload;
};
const setCustomerStats = (state, action) => {
    state.stats = action.payload;
};
const setCustomerInvoices = (state, action) => {
    state.invoices.data = action.payload;
    state.invoices.loading = false;
};
const setCustomerInvoice = (state, action) => {
    state.invoice.data = action.payload;
    state.invoice.loading = false;
};
const replaceClientOnCustomer = (state, action) => {
    if (!state.model)
        return;
    const updatedClient = action.payload;
    const clientIndex = state.model.clients.findIndex((c) => c.id == updatedClient.id);
    if (clientIndex > -1) {
        state.model.clients[clientIndex] = updatedClient;
    }
};
export default createSlice({
    name: "user",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getCustomerAction.fulfilled, setCustomer)
            .addCase(getCustomerStatsAction.fulfilled, setCustomerStats)
            .addCase(getCustomerInvoicesAction.fulfilled, setCustomerInvoices)
            .addCase(getCustomerInvoicesAction.pending, ({ invoices }) => {
            invoices.loading = true;
        })
            .addCase(getCustomerInvoicesAction.rejected, ({ invoices }) => {
            invoices.loading = false;
        })
            .addCase(getCustomerInvoiceAction.fulfilled, setCustomerInvoice)
            .addCase(getCustomerInvoiceAction.pending, ({ invoice }) => {
            invoice.loading = true;
        })
            .addCase(getCustomerInvoiceAction.rejected, ({ invoice }) => {
            invoice.loading = false;
        })
            .addCase(updateCustomerClientAction.fulfilled, replaceClientOnCustomer)
            .addCase(resetAppStateAction, () => initialState);
    },
});
