import * as React from "react";
import styled from "styled-components";
import { style as styleConfig } from "../../../config";
const { media } = styleConfig;
import IconPrimitive from "./Icon";
const Root = styled.span `
  display: inline-block;
  width: 30px;
  height: 30px;

  @media (${media.desktop}) {
    width: 20px;
    height: 20px;
  }
`;
const Icon = styled(IconPrimitive) `
  position: absolute;
  width: 30px;
  height: 30px;

  @media (${media.desktop}) {
    width: 20px;
    height: 20px;
  }
`;
const Input = styled.input `
  opacity: 0;
  left: 0;
  width: 30px;
  height: 30px;

  @media (${media.desktop}) {
    width: 20px;
    height: 20px;
  }
`;
export default function Checkbox(props) {
    return (React.createElement(Root, null,
        props.checked ? (React.createElement(Icon, { color: "primary", icon: "checkboxChecked", margin: "0", width: "100%", height: "100%" })) : (React.createElement(Icon, { color: "primary", icon: "checkboxUnchecked", margin: "0", width: "100%", height: "100%" })),
        React.createElement(Input, Object.assign({ type: "checkbox" }, props))));
}
