import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { selectIsLoadingUser, selectLoggedIn, selectTosAccepted } from "selectors/user";
import { routes } from "config";
import AcceptTermsOfService from "../AcceptTermsOfService";
import { Fragment } from "react";
export default function ProtectedElement(props) {
    const { component } = props;
    const loggedIn = useSelector(selectLoggedIn);
    const isLoading = useSelector(selectIsLoadingUser);
    const tosAccepted = useSelector(selectTosAccepted);
    const location = useLocation();
    const redirectPath = props.redirectPath || routes.root();
    if (isLoading)
        return null;
    if (loggedIn && tosAccepted) {
        return React.createElement(Fragment, null, component);
    }
    else if (loggedIn && !tosAccepted) {
        return React.createElement(AcceptTermsOfService, null);
    }
    else {
        return React.createElement(Navigate, { to: { pathname: redirectPath }, state: { from: location } });
    }
}
