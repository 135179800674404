import React, { useState } from "react";
import moment from "moment";
import styled from "styled-components";
import { style as styleConfig, externalLinks } from "../config";
import { updateUserAction } from "slices/user";
import FlexContainer from "./shared/layout/FlexContainer";
import FlexItem from "./shared/layout/FlexItem";
import { H2 } from "./shared/primitives/Heading";
import { Paragraph } from "./shared/primitives/Paragraph";
import Checkbox from "./shared/primitives/Checkbox";
import Button from "./shared/primitives/Button";
import { RouterLink } from "./shared/primitives/Link";
import { useAppDispatch } from "reduxStore";
const Label = styled.label `
  display: flex;
  align-items: center;

  > span {
    margin-left: 1em;
  }
`;
export default function AcceptNewTermsOfService() {
    const dispatch = useAppDispatch();
    const [tosAccepted, setTosAccepted] = useState(false);
    const handleChange = () => setTosAccepted(!tosAccepted);
    const handleClick = () => dispatch(updateUserAction({ tosAcceptedAt: moment().toISOString() }));
    return (React.createElement(FlexContainer, { flexDirection: "column" },
        React.createElement(FlexItem, null,
            React.createElement(FlexContainer, { flexDirection: "column" },
                React.createElement(H2, null, "AGB akzeptieren")),
            React.createElement(Paragraph, null,
                React.createElement(Label, { htmlFor: "acceptTos" },
                    React.createElement(Checkbox, { name: "acceptTos", onChange: handleChange, checked: tosAccepted, value: "1" }),
                    React.createElement("span", null,
                        "Ich erkl\u00E4re mich mit den ",
                        React.createElement(RouterLink, { to: externalLinks.tos }, "AGB"),
                        " von Triaphon einverstanden."))),
            React.createElement(Button, { inverse: true, disabled: !tosAccepted, border: styleConfig.colors.primary, onClick: handleClick }, "Weiter zum Dashboard"))));
}
