export function selectUser(state) {
    return state.user.model;
}
export function selectLoggedIn(state) {
    return state.user.model !== null;
}
export function selectIsLoadingUser(state) {
    return state.user.isLoading;
}
export function selectTosAccepted(state) {
    const user = state.user.model;
    return !!user && !!user.tosAcceptedAt;
}
