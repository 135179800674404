import React from "react";
import styled from "styled-components";
import Button from "../../shared/primitives/Button";
import Icon from "../../shared/primitives/Icon";
import { style as styleConfig } from "../../../config";
const { media } = styleConfig;
const Root = styled.div `
  position: fixed;
  font-size: 0.8em;
  bottom: 0;
  height: 6em;
  background-color: white;
  width: 100%;
`;
const DeselectAll = styled.a `
  position: fixed;
  right: 0;
  margin: 0.5em 0.5em 0 0;
`;
const Content = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 100%;

  @media (${media.desktop}) {
    flex-direction: row;
    justify-content: center;
  }
`;
const ActionButton = styled(Button) `
  width: inherit;
  line-height: 2em;
  padding: 0 1em;

  @media (${media.desktop}) {
    margin-left: 2em;
  }
`;
export default function SnappyControls(props) {
    const { shiftIds, handleSave, handleDeselectAll, saveButtonContent, buttonInverse } = props;
    const handleClick = () => {
        handleSave(shiftIds);
    };
    if (shiftIds.length > 0) {
        return (React.createElement(Root, null,
            handleDeselectAll && (React.createElement(DeselectAll, { onClick: handleDeselectAll },
                React.createElement(Icon, { color: "primary", margin: "0", height: "1.5em", width: "1.5em", icon: "cross" }))),
            React.createElement(Content, null,
                shiftIds.length,
                " ",
                shiftIds.length > 1 ? " Schichten" : " Schicht",
                " ausgew\u00E4hlt",
                React.createElement(ActionButton, { inverse: buttonInverse, border: styleConfig.colors.primary, onClick: handleClick }, saveButtonContent))));
    }
    else {
        return null;
    }
}
