import * as React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { selectLoggedIn } from "../../selectors/user";
import { redirectToOAuthClient, isOAuth2Request } from "../helpers/oAuthRedirect";
import LogIn from "../LogIn";
import { routes } from "../../config";
export default function LoginAwareElement() {
    const location = useLocation();
    const loggedOut = useSelector((state) => !selectLoggedIn(state));
    useEffect(() => {
        const urlParams = location.search;
        if (!loggedOut && isOAuth2Request(urlParams)) {
            redirectToOAuthClient(urlParams);
        }
    }, [loggedOut, location.search]);
    if (loggedOut) {
        return React.createElement(LogIn, null);
    }
    else {
        return React.createElement(Navigate, { to: routes.root(), state: { from: location } });
    }
}
