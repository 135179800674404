var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import axios from "axios";
import { withNotifySentry } from "./helpers";
export function getShifts() {
    return __awaiter(this, void 0, void 0, function* () {
        return yield withNotifySentry(() => axios.get("/api/shifts"));
    });
}
export function getOwnShifts() {
    return __awaiter(this, void 0, void 0, function* () {
        return yield withNotifySentry(() => axios.get("/api/translator/shifts"));
    });
}
export function createShift() {
    return __awaiter(this, void 0, void 0, function* () {
        return yield withNotifySentry(() => axios.post("/api/shifts"));
    });
}
export function destroyShift(id) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield withNotifySentry(() => axios.delete(`/api/shifts/${id}`));
    });
}
export function assignShifts(ids) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield withNotifySentry(() => axios.post("/api/translator/shifts", { assign_ids: ids }));
    });
}
export function unassignShifts(ids) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield withNotifySentry(() => axios.post("/api/translator/shifts", { unassign_ids: ids }));
    });
}
