var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { resetAppStateAction } from "actions/application";
import { assignShifts, createShift, destroyShift, getOwnShifts, getShifts, unassignShifts } from "api/shifts";
import moment from "moment";
import { castArray } from "lodash-es";
const initialState = { isLoading: false, models: [], errors: [] };
export const getShiftsAction = createAsyncThunk("shifts/load", () => __awaiter(void 0, void 0, void 0, function* () { return yield getShifts(); }));
export const getOwnShiftsAction = createAsyncThunk("shifts/loadOwn", () => __awaiter(void 0, void 0, void 0, function* () { return yield getOwnShifts(); }));
export const createShiftAction = createAsyncThunk("shifts/create", () => __awaiter(void 0, void 0, void 0, function* () { return yield createShift(); }));
export const destroyShiftAction = createAsyncThunk("shifts/destroy", (id) => __awaiter(void 0, void 0, void 0, function* () { return yield destroyShift(id); }));
export const assignShiftsAction = createAsyncThunk("shifts/assign", (ids) => __awaiter(void 0, void 0, void 0, function* () { return yield assignShifts(ids); }));
export const unassignShiftsAction = createAsyncThunk("shifts/unassign", (ids) => __awaiter(void 0, void 0, void 0, function* () { return yield unassignShifts(ids); }));
const setIsLoading = (state) => {
    state.isLoading = true;
    state.errors = [];
};
const replaceShifts = (state, action) => {
    state.isLoading = false;
    state.models = action.payload;
};
const replaceErrors = (state, error) => {
    state.isLoading = false;
    state.errors = [error];
};
const unsetErrors = (state) => {
    state.errors = [];
};
const replaceOrAddShifts = (state, action) => {
    const payloadShifts = castArray(action.payload);
    payloadShifts.forEach((shift) => {
        const stateIndex = state.models.findIndex((stateShift) => stateShift.id === shift.id);
        if (stateIndex >= 0) {
            state.models[stateIndex] = shift;
        }
        else {
            state.models.push(shift);
        }
    });
};
const shiftsSlice = createSlice({
    name: "sessions",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getShiftsAction.pending, setIsLoading)
            .addCase(getShiftsAction.fulfilled, replaceShifts)
            .addCase(getShiftsAction.rejected, (state, action) => replaceErrors(state, action.error))
            .addCase(getOwnShiftsAction.pending, setIsLoading)
            .addCase(getOwnShiftsAction.fulfilled, replaceShifts)
            .addCase(getOwnShiftsAction.rejected, (state, action) => replaceErrors(state, action.error))
            .addCase(createShiftAction.pending, setIsLoading)
            .addCase(createShiftAction.rejected, (state, action) => replaceErrors(state, action.error))
            .addCase(createShiftAction.fulfilled, (state, action) => {
            // set startsAt to current time since on mobile devices the current time may be off by 1s
            const newShift = Object.assign(Object.assign({}, action.payload), { startsAt: moment().toISOString() });
            state.isLoading = false;
            state.models.unshift(newShift);
        })
            .addCase(destroyShiftAction.pending, setIsLoading)
            .addCase(destroyShiftAction.rejected, (state, action) => replaceErrors(state, action.error))
            .addCase(destroyShiftAction.fulfilled, (state, action) => {
            state.isLoading = false;
            state.models = state.models.filter((shift) => shift.id != action.payload.id);
        })
            .addCase(assignShiftsAction.pending, unsetErrors)
            .addCase(assignShiftsAction.rejected, (state, action) => replaceErrors(state, action.error))
            .addCase(assignShiftsAction.fulfilled, replaceOrAddShifts)
            .addCase(unassignShiftsAction.pending, unsetErrors)
            .addCase(unassignShiftsAction.rejected, (state, action) => replaceErrors(state, action.error))
            .addCase(unassignShiftsAction.fulfilled, replaceOrAddShifts)
            .addCase(resetAppStateAction, () => initialState);
    },
});
export default shiftsSlice;
