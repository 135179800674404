import { product, style as styleConfig } from "config";
import TriaphonLogo from "logos/triaphon/logo.png";
import TriaphonLogoAt2x from "logos/triaphon/logo@2x.png";
import TriaphonFooterLogo from "logos/triaphon/logo_footer.png";
import TriaphonFooterLogoAt2x from "logos/triaphon/logo_footer@2x.png";
import HykistLogo from "logos/hykist/logo.png";
import HykistLogoAt2x from "logos/hykist/logo@2x.png";
import styled from "styled-components";
import React from "react";
const FooterLogo = styled.img `
  max-width: 100%;
  vertical-align: middle;
`;
const HeaderLogo = styled(FooterLogo) `
  @media (${styleConfig.media.desktop}) {
    max-height: 100%;
    max-width: 211px;
  }
`;
const NavigationLogo = styled.img `
  max-width: 25%;
  vertical-align: middle;
  margin: 1em 0;
`;
export default function LogoForProduct(props) {
    const { inFooter = false, inNavigation = false } = props;
    let LogoImage = inFooter ? TriaphonFooterLogo : TriaphonLogo;
    let LogoImageAt2x = inFooter ? TriaphonFooterLogoAt2x : TriaphonLogoAt2x;
    if (product === "hykist") {
        LogoImage = HykistLogo;
        LogoImageAt2x = HykistLogoAt2x;
    }
    const logoProps = {
        src: LogoImage,
        srcSet: `${LogoImage} 1x, ${LogoImageAt2x} 2x`,
        alt: "Triaphon Logo",
    };
    if (inFooter) {
        return React.createElement(FooterLogo, Object.assign({}, logoProps));
    }
    else if (inNavigation) {
        return React.createElement(NavigationLogo, Object.assign({}, logoProps));
    }
    else {
        return React.createElement(HeaderLogo, Object.assign({}, logoProps));
    }
}
