var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { destroyUserSessionAction } from "slices/userSession";
import { closeMenuDisplayAction } from "slices/mobile";
import Icon from "components/shared/primitives/Icon";
import { style as styleConfig, routes, externalLinks } from "config";
import LogoForProduct from "components/shared/primitives/LogoForProduct";
import { Fragment } from "react";
import { useAppDispatch } from "reduxStore";
import Menu, { MenuItem } from "components/shared/Menu";
import MobileOnly from "components/shared/primitives/MobileOnly";
import Link, { RouterLink } from "components/shared/primitives/Link";
import { selectUser } from "selectors/user";
import DesktopOnly from "components/shared/primitives/DesktopOnly";
import { renderUser } from "components/helpers/recordRenderers";
import FlexContainer from "components/shared/layout/FlexContainer";
const Root = styled.nav `
  background-color: ${({ dark }) => (dark ? styleConfig.colors.lightGray : styleConfig.colors.white)};
  position: fixed;
  top: 0;
  width: 100%;
  box-sizing: border-box;
  padding: 1em 1em;
  z-index: ${styleConfig.zIndex.Header.Navigation};
  max-height: 100%;
  overflow-y: scroll;

  @media (${styleConfig.media.desktop}) {
    width: auto;
    background-color: transparent;
    padding: 0;
    position: initial;
    text-align: right;
    overflow-y: hidden;
  }
`;
const AlignedMobileOnly = styled(MobileOnly) `
  ${({ right }) => right && "text-align: right;"}
  ${({ left }) => left && "text-align: left;"}
`;
const NavigationList = styled.div `
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-end;
  gap: 1em;

  @media (${styleConfig.media.desktop}) {
    flex-direction: row;
  }
`;
function WebsiteNavigationItem() {
    return (React.createElement(Link, { href: externalLinks.mainLandingPage, target: "_blank", rel: "noopener noreferrer" }, "Zur Webseite"));
}
function LogoutNavigationItem(props) {
    const { handleLogout } = props;
    return React.createElement(Link, { onClick: handleLogout }, "Abmelden");
}
function TranslatorNavigationItems(props) {
    const { handleLogout } = props;
    return (React.createElement(Fragment, null,
        React.createElement(RouterLink, { to: routes.events() }, "Termine"),
        React.createElement(RouterLink, { to: routes.teachingMaterial() }, "Schulungsmaterial"),
        React.createElement(RouterLink, { to: routes.translatorCreditNotes() }, "Gutschriften"),
        React.createElement(RouterLink, { to: routes.translatorStats() }, "Meine Zeiten"),
        React.createElement(RouterLink, { to: routes.root() }, "Meine Schichten"),
        React.createElement(WebsiteNavigationItem, null),
        React.createElement(LogoutNavigationItem, { handleLogout: handleLogout })));
}
function UnknownUserNavigationItems(props) {
    const { handleLogout } = props;
    return (React.createElement(Fragment, null,
        React.createElement(WebsiteNavigationItem, null),
        React.createElement(LogoutNavigationItem, { handleLogout: handleLogout })));
}
function CustomerNavigationItems(props) {
    const { handleLogout, user } = props;
    const menuButton = (toggleMenu) => (React.createElement(Link, { onClick: toggleMenu },
        React.createElement(FlexContainer, { alignItems: "flex-start", gap: "1em" },
            renderUser(user),
            React.createElement(Icon, { icon: "burger", margin: "0", "aria-label": "Men\u00FCpunkte anzeigen" }))));
    const menuItems = (closeMenu) => (React.createElement(Fragment, null,
        React.createElement(MenuItem, { onClick: closeMenu },
            React.createElement(RouterLink, { to: routes.root() }, "Startseite")),
        React.createElement(MenuItem, { onClick: closeMenu },
            React.createElement(Link, { href: externalLinks.teachingMaterial, target: "_blank", rel: "noopener noreferrer" }, "Schulungsmaterial")),
        React.createElement(MenuItem, { onClick: closeMenu },
            React.createElement(RouterLink, { to: routes.customerStats() }, "Statistik")),
        React.createElement(MenuItem, { onClick: closeMenu },
            React.createElement(RouterLink, { to: routes.managePhoneNumbers() }, "Telefonnummern")),
        React.createElement(MenuItem, { onClick: closeMenu },
            React.createElement(RouterLink, { to: routes.manageAddress() }, "Adresse")),
        React.createElement(MenuItem, { onClick: closeMenu },
            React.createElement(RouterLink, { to: routes.paymentModel() }, "Konditionen")),
        React.createElement(MenuItem, { onClick: closeMenu },
            React.createElement(RouterLink, { to: routes.customerInvoices() }, "Rechnungen")),
        React.createElement(MenuItem, { onClick: closeMenu },
            React.createElement(Link, { onClick: handleLogout }, "Abmelden"))));
    return (React.createElement(Fragment, null,
        React.createElement(WebsiteNavigationItem, null),
        React.createElement(MobileOnly, null,
            React.createElement(NavigationList, null, menuItems(() => { }))),
        React.createElement(DesktopOnly, null,
            React.createElement(Menu, { button: menuButton }, menuItems))));
}
function NavigationItems(props) {
    const { user } = props, rest = __rest(props, ["user"]);
    if (!user)
        return React.createElement(WebsiteNavigationItem, null);
    if (user.translatorId) {
        return React.createElement(TranslatorNavigationItems, Object.assign({}, rest));
    }
    else if (user.customerId) {
        return React.createElement(CustomerNavigationItems, Object.assign({}, rest, { user: user }));
    }
    else {
        return React.createElement(UnknownUserNavigationItems, Object.assign({}, rest));
    }
}
export default function Navigation() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectUser);
    const closeBurgerMenu = () => {
        dispatch(closeMenuDisplayAction());
    };
    const handleLogout = (event) => {
        event.preventDefault();
        const params = (user === null || user === void 0 ? void 0 : user.customerId) ? "?type=customer" : undefined;
        dispatch(destroyUserSessionAction()).then(() => navigate(routes.login(params)));
    };
    return (React.createElement(Root, { dark: !user },
        React.createElement(AlignedMobileOnly, { right: true },
            React.createElement("a", { onClick: closeBurgerMenu },
                React.createElement(Icon, { icon: "cross", color: "black", margin: "0" }))),
        React.createElement(NavigationList, null,
            React.createElement(NavigationItems, { handleLogout: handleLogout, user: user })),
        React.createElement(AlignedMobileOnly, { left: true },
            React.createElement(RouterLink, { to: routes.root() },
                React.createElement(LogoForProduct, { inNavigation: true })))));
}
