import * as React from "react";
import { useState } from "react";
import styled from "styled-components";
import { formatWeekspan } from "../helpers/dateFormatting";
import { H3 } from "./primitives/Heading";
import Link from "./primitives/Link";
import Icon from "./primitives/Icon";
import { style as styleConfig } from "../../config";
const { colors } = styleConfig;
const Root = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: max-content;
  box-sizing: border-box;
  border-bottom: 1px solid ${colors.primary};

  @media print {
    display: none;
  }
`;
export const DefaultDateDisplay = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid ${(p) => (p.underlined ? colors.primary : "transparent")};
  padding: 1rem 0;

  @media print {
    display: none;
  }
`;
const IconBox = styled.div `
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 25%;
  box-sizing: border-box;
  border-bottom: 1px solid ${(p) => (p.underlined ? colors.primary : "transparent")};
`;
export default function DateControls(props) {
    const { disabled, currentDay, setCurrentDay, dateDisplay, start, delta = 7, deltaUnit = "days", displayFormatter = formatWeekspan, jumpToCurrentText = "Zur aktuellen Woche", direction = "future", } = props;
    const [isHoveredLeft, setIsHoveredLeft] = useState(false);
    const [isHoveredRight, setIsHoveredRight] = useState(false);
    const handleMouseEnterRight = () => setIsHoveredRight(true);
    const handleMouseLeaveRight = () => setIsHoveredRight(false);
    const handleMouseEnterLeft = () => setIsHoveredLeft(true);
    const handleMouseLeaveLeft = () => setIsHoveredLeft(false);
    const isCurrent = start.isSame(currentDay);
    const addDelta = (delta, day) => day.clone().add(delta, deltaUnit);
    const handleNavigation = (navigateTo) => () => setCurrentDay(navigateTo);
    const pastDisabled = disabled || (direction === "future" && isCurrent);
    const futureDisabled = disabled || (direction === "past" && isCurrent);
    const dateDisplayIsUnderlined = !isHoveredLeft && !isHoveredRight;
    return (React.createElement(Root, null,
        React.createElement(IconBox, { underlined: isHoveredLeft, onMouseEnter: handleMouseEnterLeft, onMouseLeave: handleMouseLeaveLeft, onClick: !pastDisabled ? handleNavigation(addDelta(-delta, currentDay)) : undefined },
            React.createElement(Icon, { color: "primary", icon: "chevronLeft", disabled: pastDisabled })),
        dateDisplay ? (dateDisplay(dateDisplayIsUnderlined, isCurrent, handleNavigation)) : (React.createElement(DefaultDateDisplay, { underlined: dateDisplayIsUnderlined },
            React.createElement(H3, { solo: true }, displayFormatter(currentDay)),
            !isCurrent && React.createElement(Link, { onClick: handleNavigation(start) }, jumpToCurrentText))),
        React.createElement(IconBox, { underlined: isHoveredRight, onMouseEnter: handleMouseEnterRight, onMouseLeave: handleMouseLeaveRight, onClick: !futureDisabled ? handleNavigation(addDelta(delta, currentDay)) : undefined },
            React.createElement(Icon, { color: "primary", icon: "chevronRight", disabled: futureDisabled }))));
}
