var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { selectCustomer } from "selectors/customer";
import { useAppDispatch } from "reduxStore";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm, useFormState } from "react-hook-form";
import { style as styleConfig } from "config";
import { updateCustomerAction } from "slices/customer";
import { customerUpdateSchema } from "formSchemas";
import FormInput from "./shared/primitives/FormInput";
import SideBySideInputs from "./shared/primitives/SideBySideInputs";
import FlexContainer from "./shared/layout/FlexContainer";
import Button from "./shared/primitives/Button";
import Form from "./shared/primitives/Form";
import { H2 } from "./shared/primitives/Heading";
import { useShowForSomeTime } from "customHooks";
const ManageAddressViewRoot = styled(FlexContainer) `
  margin: auto;
  margin-top: 2rem;
  flex-direction: column;
  width: 95%;
  min-height: 50vh;
  justify-content: flex-start;

  @media (${styleConfig.media.desktop}) {
    max-width: 1024px;
  }
`;
function ManageAddressView(props) {
    const { customer } = props;
    const dispatch = useAppDispatch();
    const [showSuccess, setShowSuccess] = useShowForSomeTime();
    const [showError, setShowError] = useShowForSomeTime();
    const addressDefaults = customer.address || { addressLine1: "", addressLine2: "", postcode: "", city: "" };
    const formMethods = useForm({
        resolver: yupResolver(customerUpdateSchema),
        defaultValues: {
            addressesAttributes: [addressDefaults],
        },
    });
    const { handleSubmit, control } = formMethods;
    const { isSubmitting } = useFormState({ control });
    const onSubmit = (data) => __awaiter(this, void 0, void 0, function* () {
        try {
            yield dispatch(updateCustomerAction(data)).unwrap();
            setShowSuccess(true);
        }
        catch (_a) {
            setShowError(true);
        }
    });
    return (React.createElement(ManageAddressViewRoot, null,
        React.createElement(H2, null, "Adresse"),
        React.createElement(FormProvider, Object.assign({}, formMethods),
            React.createElement(Form, { action: "#", onSubmit: handleSubmit(onSubmit) },
                React.createElement(FlexContainer, { flexDirection: "column", gap: "1rem", alignItems: "flex-start", fullWidth: true },
                    React.createElement("input", { type: "hidden", name: "addressesAttributes.0.id" }),
                    React.createElement(FormInput, { name: "addressesAttributes.0.addressLine1", label: "Addresszeile 1", required: true }),
                    React.createElement(FormInput, { name: "addressesAttributes.0.addressLine2", label: "Addresszeile 2" }),
                    React.createElement(SideBySideInputs, null,
                        React.createElement(FormInput, { name: "addressesAttributes.0.postcode", label: "Postleitzahl", required: true }),
                        React.createElement(FormInput, { name: "addressesAttributes.0.city", label: "Stadt", required: true })),
                    React.createElement(Button, { auto: true, type: "submit", disabled: isSubmitting }, "Aktualisieren"),
                    showSuccess && React.createElement("span", null, "Adresse aktualisiert."),
                    showError && React.createElement("span", null, "Adresse aktualisieren fehlgeschlagen."))))));
}
export default function ManageAddress() {
    const customer = useSelector(selectCustomer);
    if (!customer)
        return null;
    return React.createElement(ManageAddressView, { customer: customer });
}
