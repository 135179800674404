import styled from "styled-components";
import { style as styleConfig } from "config";
const FlexContainer = styled.div `
  display: flex;
  flex-direction: ${(props) => props.flexDirection || "row"};
  flex-wrap: ${(props) => props.flexWrap || "nowrap"};
  justify-content: ${(props) => props.justifyContent || "center"};
  align-items: ${(props) => props.alignItems || "center"};
  align-content: ${(props) => props.alignContent || "center"};
  ${(props) => props.gap && `gap: ${props.gap};`}
  ${(props) => props.fullHeight && "height: 100%;"};
  ${(props) => props.fullWidth && "width: 100%;"};

  @media (${styleConfig.media.desktop}) {
    ${({ desktopFlexDirection }) => desktopFlexDirection && `flex-direction: ${desktopFlexDirection};`}
    ${({ desktopFlexWrap }) => desktopFlexWrap && `flex-wrap: ${desktopFlexWrap};`}
    ${({ desktopJustifyContent }) => desktopJustifyContent && `justify-content: ${desktopJustifyContent};`}
    ${({ desktopAlignItems }) => desktopAlignItems && `align-items: ${desktopAlignItems};`}
    ${({ desktopAlignContent }) => desktopAlignContent && `align-content: ${desktopAlignContent};`}
  }
`;
export default FlexContainer;
