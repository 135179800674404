import * as React from "react";
import { Helmet } from "react-helmet";
import { externalLinks } from "../config";
import FlexContainer from "./shared/layout/FlexContainer";
import FlexItem from "./shared/layout/FlexItem";
import { H2 } from "./shared/primitives/Heading";
import { Paragraph } from "./shared/primitives/Paragraph";
import Title from "./shared/layout/Title";
export default function NotFound() {
    return (React.createElement(FlexContainer, { flexDirection: "column" },
        React.createElement(Helmet, null,
            React.createElement("meta", { name: "prerender-status-code", content: "404" })),
        React.createElement(Title, null, "404"),
        React.createElement(FlexItem, { column: 1 / 1 },
            React.createElement(H2, null, "Das tut uns leid, aber wir haben hier nichts anzuzeigen."),
            React.createElement(Paragraph, null,
                "Wenn du mehr \u00FCber Triaphon erfahren willst, dann",
                " ",
                React.createElement("a", { href: externalLinks.mainLandingPage }, "schau doch mal hier vorbei"),
                "."))));
}
