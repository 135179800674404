var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { Fragment, useEffect, useState } from "react";
import { FormProvider, useFieldArray, useForm, useFormContext, useFormState } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import styled from "styled-components";
import { externalLinks, style as styleConfig } from "config";
import { customerSchema } from "formSchemas";
import { useAppDispatch } from "reduxStore";
import { createCustomerAction } from "slices/customer";
import Button from "components/shared/primitives/Button";
import Error from "components/shared/primitives/Error";
import FlexContainer from "components/shared/layout/FlexContainer";
import Section from "./shared/layout/Section";
import Paragraph from "./shared/primitives/Paragraph";
import Link from "./shared/primitives/Link";
import FormInput from "./shared/primitives/FormInput";
import SideBySideInputs from "./shared/primitives/SideBySideInputs";
import Form from "./shared/primitives/Form";
const Root = styled(FlexContainer) `
  background-color: ${styleConfig.colors.lightGray};
  padding: 2rem 0;
`;
function UserFields(props) {
    const { index } = props;
    return (React.createElement(FlexContainer, { flexDirection: "column", gap: "1rem", fullWidth: true },
        React.createElement(SideBySideInputs, null,
            React.createElement(FormInput, { name: `usersAttributes.${index}.firstName`, label: "Vorname", required: true }),
            React.createElement(FormInput, { name: `usersAttributes.${index}.lastName`, label: "Nachname", required: true })),
        React.createElement(FormInput, { name: `usersAttributes.${index}.email`, label: "E-Mail", required: true }),
        React.createElement(FormInput, { name: `usersAttributes.${index}.emailConfirm`, label: "E-Mail Best\u00E4tigung", required: true }),
        React.createElement(SideBySideInputs, null,
            React.createElement(FormInput, { name: `usersAttributes.${index}.title`, label: "akad. Titel" }),
            React.createElement(FormInput, { name: `usersAttributes.${index}.responsibility`, label: "Funktion" }))));
}
function ClientFields(props) {
    const { index } = props;
    const { control, register, watch, setValue } = useFormContext();
    const { fields: phoneNumbersFields, prepend, remove, } = useFieldArray({ name: `clientsAttributes.${index}.phoneNumbersAttributes`, control });
    const customerName = watch("name");
    useEffect(() => {
        setValue(`clientsAttributes.${index}.name`, customerName);
    }, [customerName, index]);
    const isFirstPhoneNumber = (phoneNumberIndex) => phoneNumberIndex === phoneNumbersFields.length - 1;
    return (React.createElement(Fragment, null,
        React.createElement("input", Object.assign({ type: "hidden" }, register(`clientsAttributes.${index}.name`))),
        React.createElement(FlexContainer, { flexDirection: "column", alignItems: "flex-start" },
            React.createElement("h4", null, "Telefonnummern"),
            React.createElement("small", null, "Bitte geben Sie die Nummern mit L\u00E4ndervorwahl an (z.B. f\u00FCr Berlin +4930\u2026). Unterdr\u00FCckte Nummern werden von Triaphon nicht unterst\u00FCtzt.")),
        React.createElement(FlexContainer, { alignItems: "flex-start", flexDirection: "column", gap: "1rem", fullWidth: true },
            phoneNumbersFields.map((item, phoneNumberIndex) => (React.createElement(FlexContainer, { key: item.id, alignItems: "flex-start", gap: "1rem", fullWidth: true },
                React.createElement(FormInput, { name: `clientsAttributes.${index}.phoneNumbersAttributes.${phoneNumberIndex}.number`, label: "Nummer", required: true }),
                !isFirstPhoneNumber(phoneNumberIndex) && (React.createElement(Button, { auto: true, inverse: true, onClick: () => remove(phoneNumberIndex) }, "X"))))),
            React.createElement(Button, { auto: true, slim: true, inverse: true, onClick: () => prepend({ number: "" }) }, "Weitere Nummer hinzuf\u00FCgen"))));
}
export default function RegisterCustomer() {
    const [successfullyRegistered, setSuccessfullyRegistered] = useState(false);
    const [hasGeneralError, setHasGeneralError] = useState(false);
    const dispatch = useAppDispatch();
    const formMethods = useForm({
        resolver: yupResolver(customerSchema),
        defaultValues: {
            usersAttributes: [{ firstName: "", lastName: "", title: null, responsibility: "", email: "", emailConfirm: "" }],
            name: "",
            addressesAttributes: [{ addressLine1: "", addressLine2: "", postcode: "", city: "" }],
            clientsAttributes: [{ name: "", phoneNumbersAttributes: [{ number: "" }] }],
        },
    });
    const { handleSubmit, control } = formMethods;
    const { isSubmitting } = useFormState({ control });
    const { fields: usersFields } = useFieldArray({ name: "usersAttributes", control });
    const { fields: clientsFields } = useFieldArray({ name: "clientsAttributes", control });
    const onSubmit = (data) => __awaiter(this, void 0, void 0, function* () {
        setHasGeneralError(false);
        try {
            yield dispatch(createCustomerAction(data)).unwrap();
            setSuccessfullyRegistered(true);
        }
        catch (e) {
            setHasGeneralError(true);
        }
    });
    if (successfullyRegistered) {
        return (React.createElement(Section, { headingText: "Vielen Dank f\u00FCr Ihre Anmeldung!" },
            React.createElement("span", null, "Ihre Daten werden gepr\u00FCft und Sie erhalten in K\u00FCrze eine E-Mail von uns.")));
    }
    return (React.createElement(Root, null,
        React.createElement(FormProvider, Object.assign({}, formMethods),
            React.createElement(Form, { action: "#", onSubmit: handleSubmit(onSubmit) },
                React.createElement(FlexContainer, { alignItems: "flex-start", flexDirection: "column", gap: "1rem" },
                    React.createElement("h2", null, "Registrieren"),
                    usersFields.map((item, index) => (React.createElement(UserFields, { key: item.id, index: index }))),
                    React.createElement(FlexContainer, { flexDirection: "column", gap: "3rem", fullWidth: true },
                        React.createElement("div", null,
                            React.createElement("h4", null, "Einrichtung"),
                            React.createElement(FlexContainer, { alignItems: "flexStart", flexDirection: "column", gap: "1rem", fullWidth: true },
                                React.createElement(FormInput, { name: "name", label: "Name der Einrichtung", required: true }),
                                React.createElement(FlexContainer, { flexDirection: "column", gap: "1rem", fullWidth: true },
                                    React.createElement(FormInput, { name: "addressesAttributes.0.addressLine1", label: "Addresszeile 1", required: true }),
                                    React.createElement(FormInput, { name: "addressesAttributes.0.addressLine2", label: "Addresszeile 2" }),
                                    React.createElement(SideBySideInputs, null,
                                        React.createElement(FormInput, { name: "addressesAttributes.0.postcode", label: "Postleitzahl", required: true }),
                                        React.createElement(FormInput, { name: "addressesAttributes.0.city", label: "Stadt", required: true }))),
                                clientsFields.map((item, index) => (React.createElement(ClientFields, { key: item.id, index: index }))))),
                        React.createElement(FormInput, { name: "pointedToTriaphonBy", label: "Wie haben Sie von Triaphon erfahren?" })),
                    React.createElement(Paragraph, { style: { backgroundColor: "rgba(0,0,0,0.03)", padding: "1em" } },
                        React.createElement("small", null,
                            "In unserer Datenschutzerkl\u00E4rung erfahren Sie, wie wir Ihre personenbezogenen Daten verarbeiten, f\u00FCr welche Zwecke wir sie verwenden und welche Rechte Sie in Bezug auf Ihre Daten haben. Sie k\u00F6nnen unsere Datenschutzerkl\u00E4rung",
                            " ",
                            React.createElement(Link, { href: externalLinks.privacyPolicy, target: "_blank" }, "hier"),
                            " ",
                            "einsehen."),
                        React.createElement("small", { style: { display: "block" } },
                            "Beachten Sie bitte au\u00DFerdem unsere",
                            " ",
                            React.createElement(Link, { href: externalLinks.customerTos, target: "_blank" }, "AGB"),
                            " ",
                            "und unseren",
                            " ",
                            React.createElement(Link, { href: externalLinks.dpa, target: "_blank" }, "AVV"),
                            ".")),
                    React.createElement(Button, { auto: true, type: "submit", disabled: isSubmitting }, "Absenden"),
                    hasGeneralError && (React.createElement(Error, { small: false }, "Die Anfrage ist fehlgeschlagen. Bitte versuchen Sie es erneut.")))))));
}
