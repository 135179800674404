import * as React from "react";
import { useState, useEffect } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import moment from "moment";
import { Link } from "react-router-dom";
import { selectAreShiftsLoading, selectPlannableShifts } from "../../selectors/shift";
import { H1, H2 } from "../shared/primitives/Heading";
import Icon from "../shared/primitives/Icon";
import Paragraph from "../shared/primitives/Paragraph";
import FlexContainer from "../shared/layout/FlexContainer";
import FlexItem from "../shared/layout/FlexItem";
import DateControls from "../shared/DateControls";
import ShiftsList from "./presentational/ShiftsList";
import SnappyControls from "./presentational/SnappyControls";
import { style as styleConfig, routes } from "../../config";
import { useAppDispatch } from "reduxStore";
import { assignShiftsAction, getShiftsAction } from "slices/shifts";
const { colors, media } = styleConfig;
const LinkContainer = styled.div `
  text-align: center;
  width: 100%;
  margin-bottom: 1rem;
`;
const Root = styled(FlexContainer) `
  flex-direction: column;
`;
const HeadingItem = styled(FlexItem) `
  background-color: ${colors.primary};
`;
const Teaser = styled(FlexItem) `
  display: flex;
  align-items: center;
`;
const MainContent = styled(FlexItem) `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: ${colors.lightGray};
`;
const MainContentHeading = styled(H2) `
  align-self: center;

  @media (${media.desktop}) {
    align-self: flex-start;
  }
`;
export default function ShiftsPlan() {
    const dispatch = useAppDispatch();
    const shifts = useSelector(selectPlannableShifts);
    const isLoadingShifts = useSelector(selectAreShiftsLoading);
    const startOfWeek = moment().startOf("week").clone();
    const [currentDay, setCurrentDay] = useState(startOfWeek);
    const [selectedShifts, setSelectedShifts] = useState([]);
    const getShifts = () => dispatch(getShiftsAction());
    const assignShifts = (ids) => dispatch(assignShiftsAction(ids));
    useEffect(() => {
        getShifts();
    }, []);
    const handleShiftToggle = (event) => {
        const { target } = event;
        let newShifts;
        if (target.checked) {
            newShifts = selectedShifts.concat(target.value);
        }
        else {
            newShifts = selectedShifts.filter((id) => id != target.value);
        }
        setSelectedShifts(newShifts);
    };
    const handleAssignShifts = (ids) => {
        assignShifts(ids);
        setSelectedShifts([]);
    };
    const endOfSchedule = moment(startOfWeek).add(2, "w");
    return (React.createElement(Root, null,
        React.createElement(HeadingItem, { column: 1 / 1 },
            React.createElement(H1, { solo: true, color: "secondary" }, "Schichten w\u00E4hlen")),
        React.createElement(Teaser, { column: 1 / 1 },
            React.createElement(Icon, { color: "black", medium: true, icon: "calendar" }),
            React.createElement(Paragraph, null, "Trage dich verbindlich f\u00FCr Schichten ein. Du kannst sie bis kurz vor Beginn wieder absagen.")),
        React.createElement(MainContent, { column: 1 / 1, padding: "3em 0" },
            React.createElement(MainContentHeading, null, "Freie Schichten"),
            React.createElement(LinkContainer, null,
                React.createElement(Link, { to: routes.root() }, "Zur\u00FCck zur \u00DCbersicht")),
            React.createElement(DateControls, { disabled: selectedShifts.length > 0, currentDay: currentDay, setCurrentDay: setCurrentDay, start: startOfWeek }),
            !isLoadingShifts && (React.createElement(ShiftsList, { shifts: shifts, selectedShifts: selectedShifts, currentDay: currentDay.format("YYYYDDD"), handleChange: handleShiftToggle, noShiftsHint: "In dieser Woche sind bereits alle Schichten belegt.", endOfSchedule: endOfSchedule }))),
        React.createElement(SnappyControls, { handleSave: handleAssignShifts, saveButtonContent: "Eintragen", shiftIds: selectedShifts })));
}
