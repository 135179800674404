export function formatCurrency(number) {
  return number.toLocaleString("de", {
    style: "currency",
    currency: "EUR",
    currencyDisplay: "symbol",
    minimumFractionDigits: 0,
  });
}

export function maxTwoDecimals(number) {
  return Math.round((number + Number.EPSILON) * 100) / 100;
}

export function formatLocaleNumber(number) {
  return number.toLocaleString("de", {useGrouping: false})
}
