export function selectCustomer(state) {
    return state.customer.model;
}
export function selectCustomerStats(state) {
    return state.customer.stats;
}
export function selectCustomerInvoices(state) {
    return state.customer.invoices;
}
export function selectCustomerInvoice(state) {
    return state.customer.invoice;
}
