import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "reduxStore";
import moment from "moment";
import { routes } from "config";
import { selectTranslatorCreditNotes } from "selectors/translator";
import { getTranslatorCreditNotesAction } from "slices/translator";
import { formatYear } from "./helpers/dateFormatting";
import DateControls from "./shared/DateControls";
import FlexContainer from "./shared/layout/FlexContainer";
import { H2 } from "./shared/primitives/Heading";
import { RouterLink } from "./shared/primitives/Link";
import Spinner from "./shared/Spinner";
import Paragraph from "./shared/primitives/Paragraph";
import FlexItem from "./shared/layout/FlexItem";
export default function TranslatorCreditNotes() {
    const startOfYear = moment().startOf("year").clone();
    const dispatch = useAppDispatch();
    const [currentDay, setCurrentDay] = useState(startOfYear);
    const currentYear = currentDay.format("YYYY");
    const creditNotesState = useSelector(selectTranslatorCreditNotes);
    const { data: creditNotes, loading } = creditNotesState;
    useEffect(() => {
        const params = { year: currentYear };
        dispatch(getTranslatorCreditNotesAction(params));
    }, [currentYear]);
    return (React.createElement(FlexContainer, { flexDirection: "column" },
        React.createElement(FlexItem, { padding: "2em 0", desktop: { padding: "2em 0" }, column: 1 / 1 },
            React.createElement(FlexContainer, { gap: "2em", flexDirection: "column" },
                React.createElement(H2, null, "Gutschriften"),
                React.createElement(DateControls, { delta: 1, deltaUnit: "years", currentDay: currentDay, setCurrentDay: setCurrentDay, jumpToCurrentText: "Aktuelles Jahr", displayFormatter: formatYear, direction: "past", start: startOfYear }),
                React.createElement(FlexContainer, { gap: "1em", flexDirection: "column", fullWidth: true },
                    loading && React.createElement(Spinner, { color: "primary" }),
                    !loading && !(creditNotes === null || creditNotes === void 0 ? void 0 : creditNotes.length) && React.createElement(Paragraph, null, "Keine Gutschriften vorhanden."),
                    !loading &&
                        !!(creditNotes === null || creditNotes === void 0 ? void 0 : creditNotes.length) &&
                        creditNotes.map((cn) => (React.createElement(RouterLink, { key: cn.id, to: routes.translatorCreditNote(`/${cn.id}`) }, cn.sevdeskCreditNote.title))))))));
}
