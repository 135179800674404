var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as React from "react";
import { useState } from "react";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import validator from "validator";
import { Link } from "react-router-dom";
import { resetPasswordAction } from "slices/user";
import { style as styleConfig, routes } from "../config";
const { colors } = styleConfig;
import FlexContainer from "./shared/layout/FlexContainer";
import FlexItem from "./shared/layout/FlexItem";
import Paragraph from "./shared/primitives/Paragraph";
import InputField from "./shared/primitives/InputField";
import Button from "./shared/primitives/Button";
import Spinner from "./shared/Spinner";
import Title from "./shared/layout/Title";
import { useAppDispatch } from "reduxStore";
import { useDisplayCustomerPrompts } from "customHooks";
const Root = styled(FlexContainer) `
  flex-direction: column;
`;
const MainContainer = styled(FlexItem) `
  background-color: ${colors.lightGray};
`;
const SubmitButton = styled(Button) `
  margin-top: 1em;
  width: 80%;
`;
const InputWrapper = styled.div `
  width: 100%;
  margin-bottom: 1em;
`;
const Form = styled.form `
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const ErrorMessage = styled(Paragraph) `
  color: ${colors.primary};
`;
export default function PasswordReset() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { resetToken } = useParams();
    const { isCustomer, searchParamsStr } = useDisplayCustomerPrompts();
    const initialState = {
        password: "",
        passwordConfirmation: "",
    };
    const [state, setState] = useState({
        values: initialState,
        isLoading: false,
        invalidToken: false,
        touched: { password: false, passwordConfirmation: false },
        errors: initialState,
    });
    const handleChange = (event) => {
        const { name, checked, type } = event.target;
        const { value: eventValue } = event.target;
        const { values, touched } = state;
        let newValue;
        if (type === "checkbox" && !checked) {
            newValue = null;
        }
        else {
            newValue = eventValue;
        }
        setState(Object.assign(Object.assign({}, state), { values: Object.assign(Object.assign({}, values), { [name]: newValue }), errors: validate(Object.assign(Object.assign({}, values), { [name]: newValue })), touched: Object.assign(Object.assign({}, touched), { [name]: true }) }));
    };
    const handleSubmit = (event) => __awaiter(this, void 0, void 0, function* () {
        event.preventDefault();
        const { password } = state.values;
        setState(Object.assign(Object.assign({}, state), { isLoading: true }));
        if (resetToken) {
            try {
                yield dispatch(resetPasswordAction({ resetToken, password }));
                navigate(routes.login(searchParamsStr));
            }
            catch (error) {
                let invalidToken = false;
                if (error.response.status === 403) {
                    invalidToken = true;
                }
                setState(Object.assign(Object.assign({}, state), { isLoading: false, invalidToken }));
            }
        }
    });
    const validate = (values) => {
        const { password, passwordConfirmation } = values;
        const errors = Object.assign({}, initialState);
        if (!validator.isLength(password, { min: 8 })) {
            errors.password = "Muss mindestens acht Zeichen haben";
        }
        if (!validator.isLength(passwordConfirmation, { min: 8 })) {
            errors.passwordConfirmation = "Muss mindestens acht Zeichen haben";
        }
        else if (!validator.equals(password, passwordConfirmation)) {
            errors.passwordConfirmation = "Muss mit dem Passwort übereinstimmen";
        }
        return errors;
    };
    const { values, touched, errors, isLoading, invalidToken } = state;
    let retryPasswordResetPrompt = "Dein Passwort-Zurücksetzen-Link ist abgelaufen. Bitte starte den Vorgang erneut.";
    let setPasswordPrompt = "Lege ein neues Passwort fest. Mit diesem kannst du dich später bei uns anmelden.";
    if (isCustomer) {
        retryPasswordResetPrompt = "Ihr Passwort-Zurücksetzen-Link ist abgelaufen. Bitte starten Sie den Vorgang erneut.";
        setPasswordPrompt = "Legen Sie ein neues Passwort fest. Mit diesem können Sie sich später bei uns anmelden.";
    }
    return (React.createElement(Root, null,
        React.createElement(Title, null, "Neues Passwort"),
        React.createElement(MainContainer, { column: 1 / 1 },
            invalidToken && (React.createElement(ErrorMessage, null,
                React.createElement(Link, { to: routes.forgotPassword(searchParamsStr) }, retryPasswordResetPrompt),
                ".")),
            React.createElement(Paragraph, null, setPasswordPrompt),
            React.createElement(Form, { onSubmit: handleSubmit },
                React.createElement(InputWrapper, null,
                    React.createElement(InputField, { value: values.password, label: "Passwort", type: "password", name: "password", onChange: handleChange, error: touched.password && errors.password })),
                React.createElement(InputWrapper, null,
                    React.createElement(InputField, { value: values.passwordConfirmation, label: "Passwort best\u00E4tigen", type: "password", name: "passwordConfirmation", onChange: handleChange, error: touched.passwordConfirmation && errors.passwordConfirmation })),
                React.createElement(SubmitButton, { type: "submit", disabled: !!Object.values(touched).find((v) => !v) || !!Object.values(errors).find((v) => !!v) }, isLoading ? React.createElement(Spinner, null) : "Bestätigen")))));
}
